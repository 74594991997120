import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import connectUs from '../views/connectUs.vue'
import appExhibition from '../views/appExhibition.vue'
import product from '../views/product.vue'
import newList from '../views/newList.vue'
import aboutUs from '../views/aboutUs.vue'
import help from '../views/help.vue'
import newsInfo from '../views/newsInfo.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/connectUs',
    name: 'connectUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:  connectUs
  },
  {
    path: '/appExhibition',
    name: 'appExhibition',
    component:  appExhibition
  },
  {
    path: '/product',
    name: 'product',
    component:  product
  },
  {
    path: '/newList',
    name: 'newList',
    component:  newList
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component:  aboutUs,
  },
  {
    path: '/help',
    name: 'help',
    component:  help,
    meta:{
      hideNav:true
    }
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component:newsInfo,
    meta:{
      // hideNav:true
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  // base:"officialWebsite",
  routes
})

export default router
