<template>
  <div class="carouselBox" :style="carouselBoxStyle">
    <template v-for="(item, index) in imageInfoList">
      <div
        class="carousel"
        :class="{
          currentClass: currentNum == index,
          prevClass: prevNum == index,
          nextClass: nextNum == index,
        }"
        :style="item.style"
        :key="item.id"
        v-show="index == currentNum || prevNum == index || nextNum == index"
      >
        <div class="cover"></div>
        <img :src="item.img" alt="轮播图" />
      </div>
    </template>
  </div>
</template>
<script>
import { getClientWidth } from "@/tool/index";
let watchWindowSize;
export default {
  props: {
    imageList: {
      default: () => {
        return [
          {
            id: 1,
            img: require("@/assets/mien/1.jpg"),
          },
          {
            id: 2,
            img: require("@/assets/mien/2.jpg"),
          },
          {
            id: 3,
            img: require("@/assets/mien/3.jpg"),
          },
          {
            id: 4,
            img: require("@/assets/mien/4.jpg"),
          },
          {
            id: 5,
            img: require("@/assets/mien/5.jpg"),
          },
          {
            id: 6,
            img: require("@/assets/mien/6.jpg"),
          },
          {
            id: 7,
            img: require("@/assets/mien/7.jpg"),
          },
          {
            id: 8,
            img: require("@/assets/mien/8.jpg"),
          },
          {
            id: 9,
            img: require("@/assets/mien/9.jpg"),
          },
          {
            id: 10,
            img: require("@/assets/mien/10.jpg"),
          },
          {
            id: 11,
            img: require("@/assets/mien/11.jpg"),
          },
          {
            id: 12,
            img: require("@/assets/mien/12.jpg"),
          },
        ];
      },
      type: Array,
    },
  },
  data() {
    return {
      height: 300,
      width: 700,
      radio: 3,
      carouselBoxStyle: {},
      carouselStyle: {},
      prevNum: 0,
      currentNum: 1,
      nextNum: 2,
      direction: "go",
      imageInfoList: [],
    };
  },
  created() {
    let that = this;
    that.initStyle();
    watchWindowSize = function () {
      that.initStyle();
    };
    window.addEventListener("resize", watchWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
  methods: {
    nextFn() {
      this.currentNum = this.getNextNum(this.currentNum);
      this.prevNum = this.getPrevNum(this.currentNum);
      this.nextNum = this.getNextNum(this.currentNum);
    },
    prevFn() {
      this.currentNum = this.getPrevNum(this.currentNum);
      this.prevNum = this.getPrevNum(this.currentNum);
      this.nextNum = this.getNextNum(this.currentNum);
    },

    initStyle() {
      let width = getClientWidth();
      if (width > 1240) {
        width = 1200;
      } else {
        width = width - 40;
      }
      this.width = width;
      this.height = Number((width * 0.38).toFixed());
      this.carouselBoxStyle = {
        width: this.width + "px",
        height: this.height + "px",
      };
      this.imageInfoList = [];
      this.imageList.forEach((item) => {
        this.imageInfoList.push({
          ...item,
          style: {
            width: Math.floor(this.width * 0.6) + "px",
            height: this.height + "px",
          },
        });
      });
    },

    // 获取下一个索引
    getNextNum(num) {
      if (num >= this.imageList.length - 1) {
        return 0;
      }

      return num + 1;
    },
    // 获取上一个索引
    getPrevNum(num) {
      if (num <= 0) {
        return this.imageList.length - 1;
      }
      return num - 1;
    },
  },
};
</script>

<style scoped lang="less">
.carouselBox {
  position: relative;
  overflow: hidden;
}
.currentClass {
  z-index: 3;
  left: 50% !important;
  transform: translateX(-50%) scale(1) !important;
  opacity: 1 !important;
}
.prevClass {
  z-index: 2;
  left: 50% !important;
  transform: translateX(-100%) scale(0.5) !important;
  opacity: 1 !important;
  .cover {
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ) !important;
  }
}
.nextClass {
  z-index: 2;
  left: 50% !important;
  transform: translateX(0%) scale(0.5) !important;
  opacity: 1 !important;

  .cover {
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    ) !important;
  }
}

.carousel {
  transition: 800ms all;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  height: 300px;
  width: 200px;
  background-color: #cccccc;
  opacity: 0;
  transform: translateX(50%) scale(0.5);
  // border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cover {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  position: absolute;
  transition: all 500ms;
  top: 0;
  left: 0;
}
</style>