import config from "./config";
import axios from "axios";
const instance = axios.create({
  baseURL: config.baseURL,
  timeout: 113000,
});
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
/**
 * 获取列表
 * @param {*} data
 */
export function getList(data) {
  return instance.get("/websiteNews/getList", {
    params: data,
  });
}
/**
 * 获取详情
 * @param {*} data
 */
export function getNewsById(newsId) {
  return instance.get("/websiteNews/getNewsById", {
    params: { newsId },
  });
}
/**
 * 添加或更新新闻
 * @param {*} data
 */
export function addOrUpdatePaper(data) {
  return instance.post("/websiteNews/addOrUpdatePaper", data);
}

/**
 * 上传文件
 * @param {*} data
 */
export function uploadFile(file) {
  let from = new FormData();
  from.append("file", file);
  return instance.post("/websiteNews/uploadFile", from);
}

/**
 * 删除新闻
 * @param {*} newsId
 * @returns
 */
export function delNews(newsId) {
  return instance.get("/websiteNews/delNews", {
    params: { newsId },
  });
}

/**
 * 显示或隐藏新闻
 * @param {*} newsId
 * @param {*} isShow
 * @returns
 */
export function updateIsShow(newsId, isShow) {
  return instance.get("/websiteNews/updateIsShow", {
    params: { newsId, isShow },
  });
}

/**
 * 获取招聘列表
 * @returns 
 */
export function jobOnline() {
  return instance.get("/jobOnline/queryList", {
    
  });
}

// 获取轮播图
export function getBanner() {
  return instance.get("/front/banner/imageList?type=1", {
    
  });
}

// 获取使用说明目录
export function instructionsCatalogue() {
  return instance.get("/question/listTreeQuestion?type=2&flag=1", {
    
  });
}

// 获取使用说明目录
export function questionList(pageNum,pageSize=12) {
  return instance.get("/question/listTreeQuestion?type=1&flag=1", {
    params:{
      pageSize,
      pageNum
    }
  });
}
export function questionGet(id) {
  return instance.get("/question/get/"+id, {
   
  });
}



