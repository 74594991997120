<template>
  <div>
    <footer v-if="isPhone" class="phoneFoot">
      <div class="codeBox">
        <img src="../assets/code.png" alt="code" class="code" />
        <p class="codeHint">扫一扫关注公众号</p>
      </div>
      <div>
        <div class="title flex_between" @click="check(1)">
          <p class="name">关于酷骁</p>
          <img
            src="@/assets/arraws_right.png"
            alt="arraws_right"
            :class="[
              'arraws_right',
              activeIndex == 1 ? 'arraws_right_active' : '',
            ]"
          />
        </div>
        <ul
          class="childBox"
          :style="{ height: activeIndex == 1 ? '150px' : '0px' }"
        >
          <li @click="jumpAbout(1)">企业介绍</li>
          <li @click="jumpAbout(2)">企业风采</li>
          <li @click="jumpAbout(3)">企业文化</li>
          <li @click="jumpAbout(4)">管理团队</li>
          <li @click="jumpAbout(5)">合作伙伴</li>
        </ul>
      </div>
      <div>
        <div class="title flex_between" @click="check(2)">
          <p class="name">服务平台</p>
          <img
            src="@/assets/arraws_right.png"
            alt="arraws_right"
            :class="[
              'arraws_right',
              activeIndex == 2 ? 'arraws_right_active' : '',
            ]"
          />
        </div>
        <ul
          class="childBox"
          :style="{ height: activeIndex == 2 ? '30px' : '0px' }"
        >
          <li @click="jumpPath('/product')">酷骁真车源</li>
        </ul>
      </div>
      <div>
        <div class="title flex_between" @click="check(3)">
          <p class="name">新闻中心</p>
          <img
            src="@/assets/arraws_right.png"
            alt="arraws_right"
            :class="[
              'arraws_right',
              activeIndex == 3 ? 'arraws_right_active' : '',
            ]"
          />
        </div>
        <ul
          class="childBox"
          :style="{ height: activeIndex == 3 ? '60px' : '0px' }"
        >
          <li @click="jumpNews(1)">公司动态</li>
          <li @click="jumpNews(2)">行业资讯</li>
        </ul>
      </div>
      <div>
        <div class="title flex_between" @click="check(4)">
          <p class="name">帮助中心</p>
          <img
            src="@/assets/arraws_right.png"
            alt="arraws_right"
            :class="[
              'arraws_right',
              activeIndex == 4 ? 'arraws_right_active' : '',
            ]"
          />
        </div>
        <ul
          class="childBox"
          :style="{ height: activeIndex == 4 ? '60px' : '0px' }"
        >
          <li @click="jumpPath('/help?type=1')">使用说明</li>
          <li @click="jumpPath('/help?type=2')">常见问题</li>
        </ul>
      </div>
      <div>
        <div class="title flex_between" @click="check(5)">
          <p class="name">联系方式</p>
          <img
            src="@/assets/arraws_right.png"
            alt="arraws_right"
            :class="[
              'arraws_right',
              activeIndex == 5 ? 'arraws_right_active' : '',
            ]"
          />
        </div>
        <ul
          class="childBox"
          :style="{ height: activeIndex == 5 ? '90px' : '0px' }"
        >
          <li>
            地址：苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室
          </li>
          <li>电话：19951230862</li>
          <li>邮箱：kf088@coolsalecloud.com</li>
        </ul>
      </div>
      <a href="https://beian.miit.gov.cn/" rel="nofollow">
        <div class="Copyright">
          Copyright © 2021 苏州森石企业管理咨询有限公司 版权所有
          <span> 备案号：苏ICP备2021040961号 </span>
        </div>
      </a>
    </footer>
    <footer v-else class="pcFoot">
      <div class="footBox flex_between">
        <ul>
          <li>关于酷骁</li>
          <li @click="jumpAbout(1)">企业介绍</li>
          <li @click="jumpAbout(2)">企业风采</li>
          <li @click="jumpAbout(3)">企业文化</li>
          <li @click="jumpAbout(4)">管理团队</li>
          <li @click="jumpAbout(5)">合作伙伴</li>
        </ul>
        <ul>
          <li>服务平台</li>
          <li @click="jumpPath('/product')">酷骁真车源</li>
        </ul>
        <ul>
          <li>新闻中心</li>
          <li @click="jumpNews(1)">公司动态</li>
          <li @click="jumpNews(2)">行业资讯</li>
        </ul>
        <ul>
          <li>帮助中心</li>
          <li @click="jumpPath('/help?type=1')">使用说明</li>
          <li @click="jumpPath('/help?type=2')">常见问题</li>
        </ul>
        <ul>
          <li>联系方式</li>
          <li>
            地址：苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室
          </li>
          <li>电话：19951230862</li>
          <li>邮箱：kf088@coolsalecloud.com</li>
        </ul>
        <div class="codeBox">
          <img src="../assets/code.png" alt="code" class="code" />
          <p class="codeHint">扫一扫关注公众号</p>
        </div>
      </div>
      <a href="https://beian.miit.gov.cn/" rel="nofollow">
        <div class="Copyright">
          Copyright © 2021 森石车链(苏州)信息技术有限公司 版权所有
          <span> 备案号：苏ICP备2021040961号 </span>
          <span
            ><a
              href="https://beian.mps.gov.cn/#/query/webSearch?code=32050702011333"
              rel="noreferrer"
              target="_blank"
              >苏公网安备32050702011333</a
            ></span
          >
        </div>
      </a>
    </footer>
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  data() {
    return {
      isPhone: false,
      activeIndex: 1,
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 750);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 750);
    };
    window.addEventListener("resize", watchWindowSize);
  },

  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
  methods: {
    jumpNews(type) {
      let url = type ? "/newList?type=" + type : "/newList";
      if (this.$route.fullPath == url) {
        return;
      }
      this.$router.replace(url);
      window.scrollTo(0, 0);
    },
    jumpAbout(type) {
      let url = type ? "/aboutUs?type=" + type : "/aboutUs";
      if (this.$route.fullPath == url) {
        return;
      }
      this.$router.replace(url);
    },
    jumpPath(path) {
      if (this.$route.fullPath != path) {
        this.$router.replace(path);
        window.scrollTo(0, 0);
      }
    },
    check(index) {
      if (this.activeIndex == index) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 750px) and (max-width: 960px) {
  li {
    font-size: 12px !important;
  }
  .codeHint {
    font-size: 12px !important;
    text-align: center;
  }
}
@media only screen and (max-width: 750px) {
  .Copyright {
    font-size: 12px !important;
  }
}

.phoneFoot {
  //   background-color: rgb(243, 243, 243);
  background-color: #000000;
  min-height: 300px;
  padding: 30px;
  .code {
    width: 40%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .codeHint {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #ffffff;
  }
  ul {
    list-style: none;
    overflow: hidden;
    height: 0;
    padding: 0;
    margin: 0;
    transition: height 500ms;
    li {
      //   color: #555555;
      color: #aaaaaa;
      font-size: 14px;
      padding-left: 10px;
      line-height: 30px;
    }
  }
  .title {
    height: 40px;

    cursor: pointer;
    .arraws_right {
      width: 20px;
      height: auto;
      transition: transform 500ms;
    }
    .arraws_right_active {
      transform: rotate(-90deg);
    }
    .name {
      font-size: 16px;
      //   color: #3d3d3d;
      color: #ffffff;
    }
  }
}
a {
  text-decoration: none !important;
} //这个是设置a标签的默认状态去除下划线
a:visited {
  text-decoration: none !important;
} //这个是设置a标签的访问过后的状态去除下划线
a:hover {
  text-decoration: none !important;
} //这个是设置a标签的鼠标覆盖状态去除下划线
a:active {
  text-decoration: none !important;
} //这个是设置a标签的活跃状态去除下划线
.Copyright {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  border-top: 1px solid #e6e6e7;
  color: #ffffff;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 20px;
  span {
    padding-left: 10px;
  }
  a {
    color: #ffffff !important;
  }
}
.pcFoot {
  background-image: url("../assets/footBg.png");
  width: 100%;
  // height: 336px;
  background-position: center;
  background-size: cover;

  .footBox {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    align-items: flex-start;
    padding-top: 85px;
    .code {
      width: 126px;
      height: 126px;
      object-fit: contain;
    }
    .codeHint {
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      line-height: 21px;
      margin-top: 20px;
    }
    ul {
      list-style: none;
      li:nth-child(1) {
        color: #b8b8b8;
        font-size: 18px;
        line-height: 24px;
      }
      li {
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        line-height: 21px;
        margin-bottom: 21px;
        cursor: pointer;
      }
    }
  }
}
</style>