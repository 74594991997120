import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      page:""
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    setPage(vuex,page){
      vuex.state.page=page
    },
  },
  modules: {
  }
})
