<template>
  <div class="teamBox">
    <!-- arrow="always" -->
    <!-- :interval="1000" -->
    <!-- @change="changeFn" -->
    <el-carousel
      v-if="isPhone"
      height="240vw"
      arrow="never"
      indicator-position="none"
      :autoplay="false"
      loop
    >
      <el-carousel-item>
        <div class="box1 phoneBox">
          <div class="content">
            <img
              class="user"
              style="width: 100%; height: auto"
              src="@/assets/jia.png"
              alt=""
            />
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 0 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">贾钧/JIAJUN</div>
                <div class="type">创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 0 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>
                    23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验
                  </li>
                  <li>曾任英之杰投资（中国） 区域总监</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <div class="box1 phoneBox orange">
          <div class="content">
            <img
              class="user"
              style="width: 100%; height: auto"
              src="@/assets/lu.png"
              alt=""
            />
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 1 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">陆斌/LUBIN</div>
                <div class="type">联合创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 1 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>15年汽车行业工作履历。</li>
                  <li>2005年进入上海通用，伴随雪佛兰进入中国。</li>
                  <li>
                    曾任雪佛兰区域销售负责人，威马汽车联合创始人，上海
                    悠遥科技联合创始人
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item> -->
      <el-carousel-item>
        <div class="box1 phoneBox">
          <div class="content">
            <img
              class="user"
              style="width: 100%; height: auto"
              src="@/assets/jia.png"
              alt=""
            />
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 2 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">贾钧/JIAJUN</div>
                <div class="type">创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 2 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>
                    23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验
                  </li>
                  <li>曾任英之杰投资（中国） 区域总监</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <div class="box1 phoneBox orange">
          <div class="content">
            <img
              class="user"
              style="width: 100%; height: auto"
              src="@/assets/lu.png"
              alt=""
            />
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 3 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">陆斌/LUBIN</div>
                <div class="type">联合创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 3 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>15年汽车行业工作履历。</li>
                  <li>2005年进入上海通用，伴随雪佛兰进入中国。</li>
                  <li>
                    曾任雪佛兰区域销售负责人，威马汽车联合创始人，上海悠遥科技联合创始人
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item> -->
    </el-carousel>
    <el-carousel
      v-else
      height="725px"
      arrow="never"
      indicator-position="none"
      :autoplay="false"
      loop
    >
      <el-carousel-item>
        <div class="box">
          <div class="flex_around content">
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 0 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">贾钧/JIAJUN</div>
                <div class="type">创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 0 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>
                    23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验
                  </li>
                  <li>曾任英之杰投资（中国） 区域总监</li>
                </ul>
              </div>
            </div>
            <img class="user" src="@/assets/jia.png" alt="" />
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <div class="box orange">
          <div class="flex_around content">
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 1 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">陆斌/LUBIN</div>
                <div class="type">联合创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 1 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>15年汽车行业工作履历。</li>
                  <li>2005年进入上海通用，伴随雪佛兰进入中国。</li>
                  <li>
                    曾任雪佛兰区域销售负责人，威马汽车联合创始人，上海悠遥科技联合创始人
                  </li>
                </ul>
              </div>
            </div>
            <img class="user" src="@/assets/lu.png" alt="" />
          </div>
        </div>
      </el-carousel-item> -->
      <el-carousel-item>
        <div class="box">
          <div class="flex_around content">
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 2 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">贾钧/JIAJUN</div>
                <div class="type">创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 2 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>
                    23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验
                  </li>
                  <li>曾任英之杰投资（中国） 区域总监</li>
                </ul>
              </div>
            </div>
            <img class="user" src="@/assets/jia.png" alt="" />
          </div>
        </div>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <div class="box orange">
          <div class="flex_around content">
            <div>
              <div
                :class="[
                  'animate__animated',
                  currentPage == 3 ? 'animate__lightSpeedInRight' : '',
                ]"
              >
                <div class="name">陆斌/LUBIN</div>
                <div class="type">联合创始人</div>
              </div>

              <div
                :class="[
                  'info',
                  'animate__animated',
                  currentPage2 == 3 ? 'animate__slideInUp' : '',
                ]"
              >
                <ul>
                  <li>15年汽车行业工作履历。</li>
                  <li>2005年进入上海通用，伴随雪佛兰进入中国。</li>
                  <li>
                    曾任雪佛兰区域销售负责人，威马汽车联合创始人，上海悠遥科技联合创始人
                  </li>
                </ul>
              </div>
            </div>
            <img class="user" src="@/assets/lu.png" alt="" />
          </div>
        </div>
      </el-carousel-item> -->
    </el-carousel>
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  data() {
    return {
      isPhone: false,
      currentPage: 0,
      currentPage2: 0,
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 960);
    };
    window.addEventListener("resize", watchWindowSize);
  },
  methods: {
    changeFn(e) {
      // console.log(e);
      // this.currentPage = e;
      // setTimeout(() => {
      //   this.currentPage2 = e;
      // }, 500);
    },
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
};
</script>

<style scoped lang="less">
.phoneBox {
  .name {
    font-size: 25px !important;
    width: 80%;
    margin: 0 auto;
  }
  .type {
    font-size: 18px !important;
    width: 80%;
    margin: 0 auto;
  }
  .info {
    font-size: 14px !important;
    width: 80%;
    margin: 0 auto;
  }
}

.orange {
  background: rgb(239, 139, 72) !important;
}

.teamBox {
  //   background: #2477ff;
  .box {
    // height: 645px;
    background: #2477ff;
    margin-top: 80px;
    padding-bottom: 30px;
  }
  .box1 {
    margin-top: 80px;
    background: #2477ff;
    padding-bottom: 30px;
  }
  .user {
    height: 722px;
    width: auto;
    margin-top: -70px;
  }
  .name {
    font-size: 50px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .type {
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
    border-bottom: #ffffff 1px solid;
    padding-bottom: 20px;
  }
  .type::after {
    content: "";
    display: block;
    width: 35px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    bottom: -0px;
  }
  .info {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    max-width: 564px;
    margin-top: 10px;
    transform: translateY(1000px);
  }
}
</style>