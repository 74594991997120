<template>
  <div class="brightSpot">
    <customTitle
      type="white"
      english="COOLSELLHIGHLIGHTS"
      title="酷骁产品的亮点"
    ></customTitle>
    <div class="flex_around content list">
      <div class="item">
        <img src="@/assets/brightSpot/1.png" alt="" />
        <p>模式足够<span>轻</span> ，极灵活</p>
      </div>
      <div class="item">
        <img src="@/assets/brightSpot/2.png" alt="" />
        <p>痛点足够<span>重</span>，强刚需</p>
      </div>
      <div class="item">
        <img src="@/assets/brightSpot/3.png" alt="" />
        <p>市场足够<span>大</span>，可持续</p>
      </div>
      <div class="item">
        <img src="@/assets/brightSpot/4.png" alt="" />
        <p>切口足够<span>小</span>，够聚焦</p>
      </div>
      <div class="item">
        <img src="@/assets/brightSpot/5.png" alt="" />
        <p>产品足够<span>准</span>，易推广</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 960px) {
  .brightSpot {
    padding-top: 50px !important;
  }
}
@media only screen and (max-width: 750px) {
  .list {
    padding-top: 40px !important;
  }
  .brightSpot {
    padding-top: 50px !important;
    p {
      margin-top: 20px !important;
    }
  }
}
.brightSpot {
  //   height: 875px;
  width: 100%;
  background-image: url("../../assets/appBg.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding-top: 260px;
}
.list {
  flex-wrap: wrap;
}
.item:hover img {
  transform: scale(0.8);
}
.item {
  padding: 0 23px;
  padding-bottom: 100px;

  img {
    transition: transform 500ms;
    display: block;
    margin: 0 auto;
    width: 187px;
    height: 187px;
    object-fit: contain;
  }
  p {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    margin-top: 60px;
    span {
      color: rgba(255, 187, 0, 1);
      padding-left: 5px;
    }
  }
}
</style>