<template>
  <div>
    <img class="banner" src="@/assets/appBg2.jpg" alt="" />
    <div class="content" id="intro">
      <!-- <div class="typeList flex_start">
        <div @click="check()" :class="{ active: !type }">企业介绍</div>
        <div @click="check(1)" :class="{ active: type == 1 }">公司文化</div>
        <div @click="check(2)" :class="{ active: type == 2 }">管理团队</div>
        <div @click="check(3)" :class="{ active: type == 3 }">合作伙伴</div>
      </div> -->
      <customTitle
        title="森石车链简介"
        english="INTRODUCTIONOFSUZHOUSENSHI"
      ></customTitle>
      <div class="flex_between intro">
        <img class="img" src="@/assets/details.jpg" alt="img" />
        <div class="introLeft">
          <!-- <div class="title">森石车链（苏州）信息技术有限公司</div>
          <div class="text">
            苏州森石成立于2017年8月，是一家基于人工智能、区块链、大数据等技术，面
            向汽车流通产业链，提供数智化解决方案的创新型数字科技服务商。
          </div>
          <img class="line" src="@/assets/line.png" alt="" />
          <div class="text">
            利用“大数据、人工智能、区块链”等前沿技术，为汽车主机厂、4S店集团、汽车经销商在库存新车交易、科技金融、智慧物流、运输保险、数字科技等全产业链做赋能，建立面向全国的汽车流通产业链数字经济平台。通过平台技术创新和平台聚合能力，降低产业交易成本，提升产业交易效率，推动汽车流通产业链数字化升级。秉承“实现汽车产业链共同富裕”的企业愿景，构建汽车流通产业链的数字生态服务体系，助力汽车流通产业链可持续发展。
          </div> -->
          <div class="title">森石车链</div>
          <div class="text">
            森石车链成立于2017年8月，是一家基于资源数据共享、区块链、数字金融等技术，为汽车流通产业链，提供数智化解决方案的数字科技服务商。
          </div>
          <img class="line" src="@/assets/line.png" alt="" />
          <div class="title">公司产品</div>
          <div class="text">“酷骁真车源”云平台(B2B)</div>
          <img class="line" src="@/assets/line.png" alt="" />
          <div class="title">公司愿景</div>
          <div class="text">实现汽车流通产业链可持续发展</div>
        </div>
      </div>
    </div>
    <div class="courseBox" id="course">
      <div class="content">
        <div class="titleBox">
          <customTitle
            title="酷骁真车源的发展历程"
            english="THEDEVELOPMENTOFCOOLSELL"
          ></customTitle>
        </div>
        <Timeline :list="timeLineData"></Timeline>
      </div>
    </div>
    <div id="mien">
      <customTitle title="酷骁的风采" english="COOLSELLSTYLE"></customTitle>
      <mien></mien>
    </div>

    <div id="culture">
      <customTitle
        title="酷骁的企业文化"
        english="THECORPORATECULTUREOFCOOLSELL"
      ></customTitle>
      <div class="culture">
        <div class="content">
          <!-- <div
            :class="[
              'title',
              'animate__animated',
              cultureInView ? 'animate__zoomInLeft' : '',
            ]"
          >
            技术赋能 资源重构
          </div>
          <div
            style="opacity: 0"
            :class="[
              'animate__animated',
              cultureInView2 ? 'animate__slideInUp' : '',
            ]"
          >
            <div class="flex_around hintList">
              <div>酷骁是一个集客平台、一个集合经销商的平台</div>
              <div>酷骁是一个与店端不争客户、不争利润的平台</div>
            </div>
            <div class="flex_around hintList">
              <div>酷骁是一个共享库存车资源、销售渠道的平台</div>
              <div>酷骁是一个汽车流通产业集散平台、服务平台</div>
            </div>
          </div> -->

          <div class="list flex_around">
            <div class="item">
              <img class="icon" src="@/assets/cultureIcon1.png" alt="" />
              <div class="name">企业定位</div>
              <div class="English">ENTERPRISEPOSITIONING</div>
              <div class="hint">汽车流通产业链数字科技服务商</div>
            </div>
            <div class="item">
              <img class="icon" src="@/assets/cultureIcon2.png" alt="" />
              <div class="name">企业愿景</div>
              <div class="English">ENTERPRISEVISION</div>
              <div class="hint">实现汽车流通产业链可持续发展</div>
            </div>
            <div class="item">
              <img class="icon" src="@/assets/cultureIcon3.png" alt="" />
              <div class="name">企业价值观</div>
              <div class="English">CORPERATEVALUES</div>
              <div class="hint">赋能 服务 共享</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="team">
      <customTitle
        title="酷骁的创始人团队"
        english="TFE FOUNDING TEAM OF COOLSELL"
      ></customTitle>
      <team></team>
    </div>
    <div id="friend">
      <customTitle
        title="酷骁的合作伙伴"
        english="TFE PARTNER OF COOLSELL"
      ></customTitle>
      <div v-if="isPhone" class="content friendList flex_center">
        <img src="@/assets/friend2/1.png" alt="" />
        <img src="@/assets/friend2/2.png" alt="" />
        <img src="@/assets/friend2/3.png" alt="" />
        <img src="@/assets/friend2/4.png" alt="" />
        <img src="@/assets/friend2/5.png" alt="" />
        <img src="@/assets/friend2/6.png" alt="" />
        <img src="@/assets/friend2/7.png" alt="" />
        <img src="@/assets/friend2/8.png" alt="" />
        <img src="@/assets/friend2/9.png" alt="" />
        <img src="@/assets/friend2/10.png" alt="" />
        <img src="@/assets/friend2/11.png" alt="" />
        <img src="@/assets/friend2/12.png" alt="" />
        <img src="@/assets/friend2/13.png" alt="" />
        <img src="@/assets/friend2/14.png" alt="" />
        <img src="@/assets/friend2/15.png" alt="" />
        <img src="@/assets/friend2/16.png" alt="" />
      </div>
      <div v-else class="content friendList flex_center">
        <img src="@/assets/friend/1_.png" alt="" />
        <img src="@/assets/friend/2_.png" alt="" />
        <img src="@/assets/friend/3_.png" alt="" />
        <img src="@/assets/friend/4_.png" alt="" />
        <img src="@/assets/friend/5_.png" alt="" />
        <img src="@/assets/friend/6_.png" alt="" />
        <img src="@/assets/friend/7_.png" alt="" />
        <img src="@/assets/friend/8_.png" alt="" />
        <img src="@/assets/friend/9_.png" alt="" />
        <img src="@/assets/friend/10_.png" alt="" />
        <img src="@/assets/friend/11_.png" alt="" />
        <img src="@/assets/friend/12_.png" alt="" />
        <img src="@/assets/friend/13_.png" alt="" />
        <img src="@/assets/friend/14_.png" alt="" />
        <img src="@/assets/friend/15_.png" alt="" />
        <img src="@/assets/friend/16_.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { IsClientScope, offsetTop, isInView } from "@/tool/index";
let watchWindowSize;
let watchScroll;
import Timeline from "@/components/Timeline.vue";
import team from "./aboutUsCom/team.vue";
import mien from "./aboutUsCom/mien.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      type: 1,
      isPhone: false,
      timeLineData: [
        {
          date: "2017年08月",
          text: "森石车链（苏州）信息技术有限公司成立",
          textList: ["森石车链（苏州）信息技术有限公司成立"],
        },
        {
          date: "2022年06月",
          text: "落户苏州高铁新城 <br/> 与安永达成全面战略合作",
          textList: ["落户苏州高铁新城", "与安永达成全面战略合作"],
        },
        {
          date: "2022年09月",
          text: "9月5日，“酷骁真车源”云平台ios&华为、小米 应用商店上架<br/> 苏州高铁新城国资机构首轮投资",
          textList: [
            "9月5日，“酷骁真车源”云平台ios&华为、小米 应用商店上架",
            "苏州高铁新城国资机构首轮投资",
          ],
        },
        {
          date: "2023年01月",
          text: "注册客户达到400位<br/> 获得上海不积产业投资",
          textList: ["注册客户达到400位", "获得上海不积产业投资"],
        },
        {
          date: "2023年06月",
          text: "注册客户超1560位<br/> 二手车模块上线<br/> 大数据云图设计上线",
          textList: [
            "注册客户超1560位",
            "二手车模块上线",
            "大数据云图设计上线",
          ],
        },
        {
          date: "2023年11月",
          text: "酷骁首次特卖会举办，共有来自十余个不同品牌合计170余台的车辆参展，共售出11台车辆，总销售额超过200万",
          textList: [
            "酷骁首次特卖会举办，共有来自十余个不同品牌合计170余台的车辆参展，共售出11台车辆，总销售额超过200万",
          ],
        },
      ],
      // timeLineData: [
      //   {
      //     date: "2017年8月",
      //     text: "苏州森石成立，2022年6月落户于苏州高铁新城。酷骁数科是苏州森石全资子公司。",
      //   },
      //   {
      //     date: "2022年6月",
      //     text: "苏州森石（酷骁真车源云平台）作为重点科技项目，签约引入苏州高铁新城。陆续与安永、汽车之家、小柠拍、法大大、鹿车行等企业签约合作。",
      //   },
      //   {
      //     date: "2022年7月",
      //     text: "得到苏州高铁新城国资机构首轮投资。2022年7月31日，苏州森石共拥有22项软著，12项商标，9项版权。",
      //   },
      //   {
      //     date: "2022年8月",
      //     text: "“酷骁真车源”云平台ios应用商店&华为应用商店上架。与中国银行合作数字人民币B2B场景成功建立，成为苏州市首家对接B2B场景企业。",
      //   },
      //   {
      //     date: "2022年9月19日",
      //     text: "酷骁真车源正式切入生产环境，第一个客户正式客户注册认证。与交通银行完成线上支付“银企付”对接，成为江苏省首家对接成功的电子商务平台。",
      //   },
      //   {
      //     date: "2023年1月",
      //     text: "得到上海不积ProA轮投资。“酷骁真车源”2.0版本发布。启动与上海悠遥科技合资谈判工作",
      //   },
      //   {
      //     date: "2023年6月",
      //     text: "酷骁二手车板块正式上线，进一步补全了汽车流通产业链的拼图。这个板块专注于构建优质的流通渠道并提供专业的服务，以激发二手车市场的繁荣与发展。",
      //   },
      //   {
      //     date: "2023年11月",
      //     text: "酷骁成功举办了首届特卖会。这场为期三天的活动取得了卓越的销售业绩。在本次特卖会上，共有来自十余个不同品牌的车辆参展，共售出11台车辆，总销售额超过200万。",
      //   },
      // ],
      cultureInView: true,
      cultureInView2: true,
      timeObj: undefined,
    };
  },
  computed: {
    ...mapState(["page"]),
  },
  components: {
    Timeline,
    team,
    mien,
  },
  methods: {
    check(type) {
      this.type = type;
    },
    initHeight(e) {
      if (!e || !e.param) {
        return;
      }
      if (e.param.type == 1) {
        window.scrollTo({
          top: offsetTop("intro") - 140,
          behavior: "smooth",
        });
      } else if (e.param.type == 2) {
        window.scrollTo({
          top: offsetTop("mien") - 140,
          behavior: "smooth",
        });
      } else if (e.param.type == 3) {
        window.scrollTo({
          top: offsetTop("culture") - 140,
          behavior: "smooth",
        });

        this.cultureInView2 = false;
        this.cultureInView = false;

        setTimeout(() => {
          this.cultureInView = true;
        }, 200);
        setTimeout(() => {
          this.cultureInView2 = true;
        }, 1200);

        setTimeout(() => {}, 3000);
      } else if (e.param.type == 4) {
        window.scrollTo({
          top: offsetTop("team") - 140,
          behavior: "smooth",
        });
      } else if (e.param.type == 5) {
        window.scrollTo({
          top: offsetTop("friend") - 140,
          behavior: "smooth",
        });
      }
    },
  },
  watch: {
    page(e) {
      this.initHeight(e);
    },
    $route() {
      let type = this.$route.query.type;
      if (type) {
        this.initHeight({
          param: {
            type,
          },
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.initHeight(this.page);
    }, 1000);
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 1240);
    };
    // watchScroll = function (e) {
    //   clearTimeout(this.timeObj);
    //   this.timeObj = setTimeout(() => {
    //     if (that.cultureInView) {
    //       return;
    //     }
    //     that.cultureInView = false;
    //     that.cultureInView2 = false;
    //     setTimeout(() => {
    //       setTimeout(() => {
    //         that.cultureInView2 = that.cultureInView;
    //       }, 1000);
    //       that.cultureInView = true;
    //     }, 500);
    //   }, 300);
    // };
    window.addEventListener("scroll", watchScroll);
    window.addEventListener("resize", watchWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
    window.removeEventListener("scroll", watchScroll);
  },
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 960px) {
  .intro {
    display: block;
    .img {
      width: 80% !important;
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    .introLeft {
      width: 80% !important;
      margin: 0 auto !important;
      .title {
        font-size: 18px !important;
      }
      .text {
        font-size: 14px !important;
      }
    }
  }
  .titleBox {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 750px) {
  .typeList {
    margin-top: -32px !important;
    margin-bottom: 25px !important;

    div {
      cursor: none !important;
      font-size: 14px !important;
      margin: 0 20px !important;
    }
  }
  .culture {
    .title {
      font-size: 20px !important;
      padding-top: 50px !important;
      margin-bottom: 20px !important;
    }
    .hintList {
      font-size: 14px !important;
      div {
        margin-bottom: 5px !important;
      }
    }
    .item {
      margin: 0 auto;

      width: 80%;
    }
  }
  .friendList {
    margin-bottom: 50px !important;
    img {
      width: 150px !important;
      height: 80px !important;
    }
  }
}
.animate__slideInUp,
.animate__zoomInLeft {
  opacity: 1 !important;
}
.friendList {
  flex-wrap: wrap;
  margin-bottom: 100px;
  img {
    width: 300px;
    height: 160px;
    display: block;
  }
}
.culture {
  // min-height: 700px;
  width: 100%;
  // background-image: url(../assets/cultureBg.jpg);
  background-repeat: no-repeat;
  background-size: 1920px;
  background-position: top center;
  .title {
    opacity: 0;
    font-size: 40px;
    font-weight: 700;
    color: #ffbb00;
    line-height: 1;
    text-align: center;
    padding-top: 100px;
    margin-bottom: 51px;
  }
  .list {
    flex-wrap: wrap;
    margin-top: 101px;
    .item:hover .icon {
      transform: rotateY(180deg);
    }
    .item {
      width: 378px;
      height: 447px;
      background: #ffffff;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
      padding-top: 53px;
      margin-bottom: 30px;
      .icon {
        transition: all 500ms;
        display: block;
        width: 158px;
        height: 158px;
        margin: 0 auto;
      }
      .name {
        font-size: 24px;
        font-weight: 700;
        color: #3d3d3d;
        text-align: center;
        line-height: 1;
        padding-top: 47px;
        margin-top: 13px;
        // border-top: rgba(0, 0, 0, 0.3) solid 1px;
      }
      .English {
        font-size: 14px;
        font-weight: 700;
        color: #d8d8d8;
        line-height: 18px;
        text-align: center;
        margin-top: 15px;
        position: relative;
      }
      .English::after {
        content: "";
        position: absolute;
        display: block;
        width: 30px;
        width: 72px;
        height: 4px;
        background: #2477ff;
        border-radius: 2px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
      }
      .hint {
        font-size: 18px;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 1.5;
        margin-top: 34px;
        text-align: center;
      }
    }
  }
  .hintList {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    flex-wrap: wrap;
    div {
      margin-bottom: 20px;
    }
  }
}
.mien {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.courseBox {
  width: 100%;
  min-height: 902px;
  background: #f3f6fa;
  min-height: 100px;
  padding-top: 1px;
  margin-top: 100px;
  padding-bottom: 50px;
  .titleBox {
    margin-top: -50px;
  }
}
.box {
  background: #cccccc;
  margin-top: 10px;
  min-height: 300px;
}

.banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
  // margin-bottom: 30px;
}
.intro {
  flex-direction: row-reverse;
  .title {
    font-size: 30px;
    font-weight: 700;
    color: #3d3d3d;
    margin-bottom: 24px;
  }
  .line {
    width: 80%;
    margin: 24px 0;
    display: block;
  }
  .introLeft {
    margin-right: 50px;
  }
  .text {
    font-size: 18px;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 1.5;
  }
  .img {
    width: 50%;
    height: auto;
  }
}
.typeList {
  margin-top: -38px;
  margin-bottom: 50px;
  div {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.5;
    margin: 0 52px;
    padding-bottom: 10px;
    border-bottom: rgba(0, 0, 0, 0) solid 4px;
    transition: all 300ms;
    cursor: pointer;
  }
  .active {
    border-bottom: #f17118 solid 4px;
  }
}
</style>