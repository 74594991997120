<template>
  <div class="paging flex_center">
    <div @click="tap('first')" :class="['btn', pages == 1 ? 'disabled' : '']">
      首页
    </div>
    <div @click="tap('prev')" :class="['btn', pages == 1 ? 'disabled' : '']">
      上一页
    </div>
    <div class="text">
      <span> 共{{ total }}页</span>
      <span style="padding: 0 5px">/</span>
      <span style="color: #000000"> 第{{ pages }}页</span>
    </div>
    <div
      @click="tap('next')"
      :class="['btn', pages == total ? 'disabled' : '']"
    >
      下一页
    </div>
    <div @click="tap('end')" :class="['btn', pages == total ? 'disabled' : '']">
      尾页
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      default: 1,
      type: Number,
    },
    total: {
      default: 10,
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    tap(type) {
      this.$emit("tap", type);
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 750px) {
  .btn {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0 10px;
    font-size: 12px !important;
    cursor: none !important;
  }
  .text {
    position: absolute;
    top: -30px;
  }
  .paging {
    position: relative;
  }
}
@media only screen and (min-width: 750px) {
  .btn:hover {
    background-color: rgba(36, 119, 255, 1);
    color: #ffffff;
    border-color: #ffffff;
  }
}
.btn:active {
  background-color: rgba(36, 119, 255, 1);
  color: #ffffff;
  border-color: #ffffff;
}
.disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}
.disabled:hover {
  background-color: #ffffff;
  color: #3d3d3d;
  border-color: #000000;
}
.disabled:active {
  background-color: #ffffff;
  color: #3d3d3d;
  border-color: #000000;
}
.paging {
  margin: 100px 0;
}
.text {
  font-size: 18px;
  font-weight: 400;
  color: #7f7f7f;
  line-height: 24px;
  margin: 0 5px;
}
.btn {
  transition: all 500ms;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 10px;
  border: 1px solid #000000;
  margin: 0 5px;
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  cursor: pointer;
}
</style>