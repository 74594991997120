<template>
  <div class="mien">
    <div class="content">
      <carousel ref="carousel"></carousel>
    </div>
    <div class="prop" @click="prop">
      <img class="icon" src="@/assets/arrow.png" alt="" />
    </div>
    <div class="next" @click="next">
      <img class="icon" src="@/assets/arrow.png" alt="" />
    </div>
  </div>
</template>

<script>
import carousel from "@/components/carousel.vue";
export default {
  methods: {
    prop() {
      this.$refs.carousel.prevFn();
    },
    next() {
      this.$refs.carousel.nextFn();
    },
  },
  components: {
    carousel,
  },
  mounted() {},
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 750px) {
  .icon {
    width: 40px !important;
    height: 40px !important;
    object-fit: contain;
  }
}
.mien {
  position: relative;
}
.icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.prop {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  z-index: 10;
}
.next {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(180deg);
  z-index: 10;
}
</style>