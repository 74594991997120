<template>
  <div>
    <customTitle
      english="COOLSELL PORDUCT GUIDE"
      title="酷骁的产品指南"
    ></customTitle>
    <div class="content ScrollBox">
      <div class="navList flex flex_start">
        <div
          :class="[navId == item.id ? 'avtive' : '', 'nav']"
          v-for="item in navList"
          :key="item.id"
          @click="check(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <p class="hint content">
      <!-- 通过智能搜索，在酷骁真车源资源库中找到自己需要的车源 -->
      {{ hintText }}
    </p>
    <div class="content ScrollBox" id="ScrollBox">
      <div
        :style="{ left: left + 'px' }"
        class="pageList flex_start"
        @touchstart="touchstartFn"
        @touchend="touchendFn"
      >
        <!-- @touchmove="touchmoveFn" -->
        <div
          :class="[
            pageImageType == 'web' ? 'webImgBox' : 'appImgBox',
            'ScrollItem',
          ]"
          v-for="(item, index) in pageImageList"
          :key="index + '' + navId"
        >
          <img :src="item.image" alt="image" />
          <div class="name">{{ item.name }}</div>
          <p class="hintText">{{ item.hint }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  data() {
    return {
      navId: "1",
      navList: [
        {
          name: "一键找车",
          id: "1",
          type: "app",
          hint: "通过智能搜索，在酷骁真车源资源库中找到自己需要的车源",
          child: [
            {
              image: require("@/assets/pr/01/1.png"),
              name: "热门车型",
              hint: "在首页展示最近录入或最近成交的车型",
            },
            {
              image: require("@/assets/pr/01/2.png"),
              name: "名称筛选",
              hint: "通过搜索车辆名称筛选对应车辆",
            },
            {
              image: require("@/assets/pr/01/3.png"),
              name: "品牌筛选",
              hint: "通过搜索车辆品牌筛选对应车辆",
            },
            {
              image: require("@/assets/pr/01/4.jpg"),
              name: "详细筛选",
              hint: "通过车辆详细参数筛选对应车辆",
            },
            {
              image: require("@/assets/pr/01/5.jpg"),
              name: "筛选列表",
              hint: "通过筛选车辆名称展示您所选的车型",
            },
            {
              image: require("@/assets/pr/01/6.jpg"),
              name: "付款界面",
              hint: "可选择银企付或者线下转账付款",
            },
          ],
        },
        {
          name: "需求挂单",
          id: "2",
          type: "app",
          hint: "在酷骁真车源中通过发布需要车型的详细数据来匹配车源",
          child: [
            {
              image: require("@/assets/pr/02/1.png"),
              name: "一键发布",
              hint: "将您需求的车型发布给平台各大4S店匹配",
            },
            {
              image: require("@/assets/pr/02/2.jpg"),
              name: "录入信息",
              hint: "输入您需要车的具体参数",
            },
            {
              image: require("@/assets/pr/02/3.jpg"),
              name: "录入信息",
              hint: "输入您需要车的具体参数",
            },
            {
              image: require("@/assets/pr/02/4.jpg"),
              name: "开始匹配",
              hint: "平台会通过您提供的数据，匹配有对应车源的4S店",
            },
            {
              image: require("@/assets/pr/02/5.jpg"),
              name: "查看挂单",
              hint: "通过“个人中心”来查看已发布的挂单详情及匹配状态",
            },
            {
              image: require("@/assets/pr/02/6.jpg"),
              name: "查看挂单",
              hint: "通过“个人中心”来查看已发布的挂单详情及匹配状态",
            },
          ],
        },
        {
          name: "二手车",
          id: "3",
          type: "app",
          hint: "通过智能搜索，在酷骁真车源资源库中找到自己需要的车源",
          child: [
            {
              image: require("@/assets/pr/03/1.png"),
              name: "热门车型",
              hint: "在首页展示最近录入或最近成交的车型",
            },
            {
              image: require("@/assets/pr/03/2.jpg"),
              name: "车辆详情",
              hint: "展示车辆上牌时间、行驶公里等具体参数",
            },
            {
              image: require("@/assets/pr/03/3.png"),
              name: "品牌筛选",
              hint: "通过搜索车辆品牌筛选对应车辆",
            },
            {
              image: require("@/assets/pr/03/4.png"),
              name: "详细筛选",
              hint: "通过车辆详细参数筛选对应车辆",
            },
            {
              image: require("@/assets/pr/03/5.png"),
              name: "筛选列表",
              hint: "通过筛选车辆名称展示您所选的车型",
            },
            {
              image: require("@/assets/pr/01/6.jpg"),
              name: "付款界面",
              hint: "可选择银企付或者线下转账付款",
            },
          ],
        },
        {
          name: "营销活动",
          id: "4",
          type: "app",
          hint: "平台配套活动定期举办，拉新留旧提高品牌认知",
          child: [
            {
              image: require("@/assets/pr/04/1.jpg"),
              name: "促销会",
              hint: "通过拍卖方式来给新车做促销",
            },
            {
              image: require("@/assets/pr/04/2.jpg"),
              name: "促销会",
              hint: "通过拍卖方式来给新车做促销",
            },
            {
              image: require("@/assets/pr/04/3.jpg"),
              name: "促销会",
              hint: "通过拍卖方式来给新车做促销",
            },
            {
              image: require("@/assets/pr/04/4.jpg"),
              name: "特卖会",
              hint: "线上车展给品牌提高知名度",
            },
            {
              image: require("@/assets/pr/04/5.jpg"),
              name: "特卖会",
              hint: "线上车展给品牌提高知名度",
            },
            {
              image: require("@/assets/pr/04/6.jpg"),
              name: "特卖会",
              hint: "线上车展给品牌提高知名度",
            },
          ],
        },
        {
          name: "配套服务",
          id: "5",
          type: "app",
          hint: "酷骁平台，想你所想，提供一站式服务",
          child: [
            {
              image: require("@/assets/pr/05/1.jpg"),
              name: "投保主页",
              hint: "选择、查看入驻平台的保险信息",
            },
            {
              image: require("@/assets/pr/05/2.jpg"),
              name: "保险申请",
              hint: "填写保险详细系信息",
            },
            {
              image: require("@/assets/pr/05/3.jpg"),
              name: "保险申请",
              hint: "填写保险详细系信息",
            },
            {
              image: require("@/assets/pr/05/4.jpg"),
              name: "物流主页",
              hint: "选择、查看入驻平台的物流信息",
            },
            {
              image: require("@/assets/pr/05/5.jpg"),
              name: "物流申请",
              hint: "填写物流详细系信息",
            },
            {
              image: require("@/assets/pr/05/6.jpg"),
              name: "物流申请",
              hint: "填写物流详细系信息",
            },
          ],
        },
        {
          name: "互助赋能",
          id: "6",
          type: "app",
          hint: "与多家知名企业多维度深入合作",
          child: [
            {
              image: require("@/assets/pr/06/1.jpg"),
              name: "与法大大合作",
              hint: "法大大为您提供专业的法律保障，在平台放心买卖车",
            },
            {
              image: require("@/assets/pr/06/2.jpg"),
              name: "与法大大合作",
              hint: "法大大为您提供专业的法律保障，在平台放心买卖车",
            },
            {
              image: require("@/assets/pr/06/3.jpg"),
              name: "与中国银行合作",
              hint: "数字货币结算，为您的交易再添保障",
            },
            {
              image: require("@/assets/pr/06/4.jpg"),
              name: "与中国银行合作",
              hint: "数字货币结算，为您的交易再添保障",
            },
            {
              image: require("@/assets/pr/06/5.jpg"),
              name: "与银企付合作",
              hint: "为交易提供多样性，便利性",
            },
            {
              image: require("@/assets/pr/06/6.jpg"),
              name: "与银企付合作",
              hint: "为交易提供多样性，便利性",
            },
          ],
        },
        {
          name: "后台管理",
          id: "7",
          type: "web",
          hint: "多种辅助功能任你试用，员工管理、后台数据一目了然",
          child: [
            {
              image: require("@/assets/pr/07/1.jpg"),
              name: "后台首页",
              hint: "通过首页，能即时查看上架车辆信息，近期销售量等重要数据",
            },
            {
              image: require("@/assets/pr/07/2.jpg"),
              name: "订单管理",
              hint: "随时查看车辆购买和销售情况",
            },
            {
              image: require("@/assets/pr/07/3.jpg"),
              name: "车辆管理",
              hint: "员工上下架的车辆一目了然，审核车辆方便快捷",
            },
            {
              image: require("@/assets/pr/07/4.jpg"),
              name: "人员管理",
              hint: "方便用户管理员工，可根据现场配备人员及时增减人数",
            },
            {
              image: require("@/assets/pr/07/5.jpg"),
              name: "订单管理",
              hint: "随时查看车辆购买和销售情况",
            },
            {
              image: require("@/assets/pr/07/6.jpg"),
              name: "车辆管理",
              hint: "员工上下架的车辆一目了然，审核车辆方便快捷",
            },
          ],
        },
      ],
      isStop: false,
      timeObj: undefined,
      timeOut: undefined,
      left: 20,
      startX: 0,
      isPhone: false,
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 750);
    if (that.isPhone) {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        that.startScroll();
      }, 1000);
    }
    watchWindowSize = function () {
      clearInterval(this.timeObj);
      that.isPhone = IsClientScope(0, 750);
      if (that.isPhone) {
        clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          that.startScroll();
        }, 1000);
      }
    };
    window.addEventListener("resize", watchWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
  mounted() {},
  computed: {
    pageImageList() {
      let find = this.navList.find((item) => {
        return item.id == this.navId;
      });
      return find.child;
    },
    hintText() {
      let find = this.navList.find((item) => {
        return item.id == this.navId;
      });
      return find.hint;
    },
    pageImageType() {
      let find = this.navList.find((item) => {
        return item.id == this.navId;
      });
      return find.type;
    },
  },
  methods: {
    startScroll(flag) {
      let box = document.getElementById("ScrollBox");
      let item = document.getElementsByClassName("ScrollItem");
      let L = item[0].clientWidth;
      let maxL = Math.ceil((1200 - box.clientWidth) / L) * L;
      if (this.pageImageType == "app") {
        maxL = (this.pageImageList.length - 1) * L;
      } else {
        maxL = 2 * L;
      }
      if (flag) {
        if (flag == "go") {
          this.left -= L;
          if (Math.abs(this.left) > maxL) {
            this.left = 20;
          }
        } else {
          this.left += L;
          if (this.left > 0) {
            this.left = 20;
          }
        }
      }
      clearInterval(this.timeObj);
      this.timeObj = setInterval(() => {
        if (!this.isStop) {
          this.left -= L;
          if (Math.abs(this.left) > maxL) {
            this.left = 20;
          }
        }
      }, 3000);
    },
    touchstartFn(e) {
      this.isStop = true;
      this.startX = e.changedTouches[0].pageX;
    },
    touchendFn(e) {
      this.isStop = false;
      let l = e.changedTouches[0].pageX - this.startX;
      console.log(l);
      if (Math.abs(l) > 20) {
        if (l > 0) {
          this.startScroll("back");
        } else {
          this.startScroll("go");
        }
      }
    },
    // touchmoveFn(e) {
    //   this.isStop = true;
    //   let box = document.getElementById("ScrollBox");
    //   let maxL = 1200 - box.clientWidth + 30;
    //   let L = e.changedTouches[0].pageX - this.startX;
    //   let end = this.left + L;
    //   if (end > 30) {
    //     this.left = 30;
    //     return;
    //   }
    //   if (Math.abs(end) > maxL) {
    //     this.left = maxL * -1;
    //     return;
    //   }
    //   this.left = end;
    //   this.startX = e.changedTouches[0].pageX;
    //   console.log(e, "mouseleaveFn");
    // },
    ScrollFn() {
      let dom = document.getElementsByClassName("pageList");
      let box = document.getElementById("ScrollBox");
      let maxL = 1200 - box.clientWidth;
    },
    check(item) {
      this.navId = item.id;
      this.left = 20;
      this.startScroll();
    },
  },
};
</script>

<style scoped lang="less">
@media only screen and (min-width: 750px) {
  .ScrollItem:hover {
    transform: scale(1.05);
  }
  .ScrollItem {
    transition: all 500ms;
  }
}
@media only screen and (max-width: 750px) {
  .webImgBox img {
    // width: 320px !important;
  }
  .navList {
    width: 1070px !important;
  }
  .nav {
    margin-right: 10px !important;
  }
  #ScrollBox {
    overflow: hidden;
    position: relative;
    height: 490px;
  }
  .pageList {
    top: 0;
    left: 20px;
    position: absolute;
  }
}
.navList {
  flex-wrap: wrap;
  width: 1200px;
  padding-bottom: 10px;
}
.ScrollBox {
  overflow-x: auto;
  //   -ms-overflow-style: none;
  //   overflow: -moz-scrollbars-none;
}
/*滚动条样式*/
::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}
/*IE 10+*/

::-webkit-scrollbar-thumb {
  width: 1px;
  //   height: 1px;
  border-radius: 10px;
  //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  width: 2px;
  //   height: 1px;
  //   border-radius: 10px;
  //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
.pageList {
  width: 1200px;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: left 500ms;
  padding-top: 10px;
  .name {
    font-size: 18px;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-block;
    border-bottom: rgba(36, 119, 255, 1) 2px solid;
  }
  .hintText {
    font-size: 14px;
    font-weight: 400;
    color: #7f7f7f;
    line-height: 18px;
    min-height: 36px;
  }
  .webImgBox {
    // display: inline-block;
    padding-right: 24px;
    margin-bottom: 30px;
    img {
      margin: 0 auto;
      display: block;
      width: 376px;
      height: 194px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
      -webkit-user-drag: none;
    }
    .hintText {
      width: 376px;
    }
  }
  .webImgBox:nth-child(3n + 3) {
    margin-right: 0 !important;
  }
  .appImgBox:last-child {
    margin-right: 0 !important;
  }
  .appImgBox {
    padding-right: 28px;
    display: inline-block;
    margin-bottom: 30px;
    .hintText {
      width: 172px;
    }
    img {
      margin: 0 auto;

      display: block;
      width: 172px;
      height: 358px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
    }
  }
}
.nav {
  width: 144px;
  height: 44px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #aaaaaa;
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  line-height: 44px;
  text-align: center;
  margin-right: 29px;
  cursor: pointer;
  transition: all 500ms;
  border-radius: 5px;
  transform: scale(0.9);
}
.hint {
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.nav:hover {
  transform: scale(1);
}
.avtive {
  background: #2477ff;
  color: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.302);
  border: 1px solid #2477ff;
}
.nav:last-child {
  margin-right: 0px !important;
}
</style>