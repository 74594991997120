var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPhone)?_c('footer',{staticClass:"phoneFoot"},[_vm._m(0),_c('div',[_c('div',{staticClass:"title flex_between",on:{"click":function($event){return _vm.check(1)}}},[_c('p',{staticClass:"name"},[_vm._v("关于酷骁")]),_c('img',{class:[
            'arraws_right',
            _vm.activeIndex == 1 ? 'arraws_right_active' : '',
          ],attrs:{"src":require("@/assets/arraws_right.png"),"alt":"arraws_right"}})]),_c('ul',{staticClass:"childBox",style:({ height: _vm.activeIndex == 1 ? '150px' : '0px' })},[_c('li',{on:{"click":function($event){return _vm.jumpAbout(1)}}},[_vm._v("企业介绍")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(2)}}},[_vm._v("企业风采")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(3)}}},[_vm._v("企业文化")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(4)}}},[_vm._v("管理团队")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(5)}}},[_vm._v("合作伙伴")])])]),_c('div',[_c('div',{staticClass:"title flex_between",on:{"click":function($event){return _vm.check(2)}}},[_c('p',{staticClass:"name"},[_vm._v("服务平台")]),_c('img',{class:[
            'arraws_right',
            _vm.activeIndex == 2 ? 'arraws_right_active' : '',
          ],attrs:{"src":require("@/assets/arraws_right.png"),"alt":"arraws_right"}})]),_c('ul',{staticClass:"childBox",style:({ height: _vm.activeIndex == 2 ? '30px' : '0px' })},[_c('li',{on:{"click":function($event){return _vm.jumpPath('/product')}}},[_vm._v("酷骁真车源")])])]),_c('div',[_c('div',{staticClass:"title flex_between",on:{"click":function($event){return _vm.check(3)}}},[_c('p',{staticClass:"name"},[_vm._v("新闻中心")]),_c('img',{class:[
            'arraws_right',
            _vm.activeIndex == 3 ? 'arraws_right_active' : '',
          ],attrs:{"src":require("@/assets/arraws_right.png"),"alt":"arraws_right"}})]),_c('ul',{staticClass:"childBox",style:({ height: _vm.activeIndex == 3 ? '60px' : '0px' })},[_c('li',{on:{"click":function($event){return _vm.jumpNews(1)}}},[_vm._v("公司动态")]),_c('li',{on:{"click":function($event){return _vm.jumpNews(2)}}},[_vm._v("行业资讯")])])]),_c('div',[_c('div',{staticClass:"title flex_between",on:{"click":function($event){return _vm.check(4)}}},[_c('p',{staticClass:"name"},[_vm._v("帮助中心")]),_c('img',{class:[
            'arraws_right',
            _vm.activeIndex == 4 ? 'arraws_right_active' : '',
          ],attrs:{"src":require("@/assets/arraws_right.png"),"alt":"arraws_right"}})]),_c('ul',{staticClass:"childBox",style:({ height: _vm.activeIndex == 4 ? '60px' : '0px' })},[_c('li',{on:{"click":function($event){return _vm.jumpPath('/help?type=1')}}},[_vm._v("使用说明")]),_c('li',{on:{"click":function($event){return _vm.jumpPath('/help?type=2')}}},[_vm._v("常见问题")])])]),_c('div',[_c('div',{staticClass:"title flex_between",on:{"click":function($event){return _vm.check(5)}}},[_c('p',{staticClass:"name"},[_vm._v("联系方式")]),_c('img',{class:[
            'arraws_right',
            _vm.activeIndex == 5 ? 'arraws_right_active' : '',
          ],attrs:{"src":require("@/assets/arraws_right.png"),"alt":"arraws_right"}})]),_c('ul',{staticClass:"childBox",style:({ height: _vm.activeIndex == 5 ? '90px' : '0px' })},[_c('li',[_vm._v(" 地址：苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室 ")]),_c('li',[_vm._v("电话：19951230862")]),_c('li',[_vm._v("邮箱：kf088@coolsalecloud.com")])])]),_vm._m(1)]):_c('footer',{staticClass:"pcFoot"},[_c('div',{staticClass:"footBox flex_between"},[_c('ul',[_c('li',[_vm._v("关于酷骁")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(1)}}},[_vm._v("企业介绍")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(2)}}},[_vm._v("企业风采")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(3)}}},[_vm._v("企业文化")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(4)}}},[_vm._v("管理团队")]),_c('li',{on:{"click":function($event){return _vm.jumpAbout(5)}}},[_vm._v("合作伙伴")])]),_c('ul',[_c('li',[_vm._v("服务平台")]),_c('li',{on:{"click":function($event){return _vm.jumpPath('/product')}}},[_vm._v("酷骁真车源")])]),_c('ul',[_c('li',[_vm._v("新闻中心")]),_c('li',{on:{"click":function($event){return _vm.jumpNews(1)}}},[_vm._v("公司动态")]),_c('li',{on:{"click":function($event){return _vm.jumpNews(2)}}},[_vm._v("行业资讯")])]),_c('ul',[_c('li',[_vm._v("帮助中心")]),_c('li',{on:{"click":function($event){return _vm.jumpPath('/help?type=1')}}},[_vm._v("使用说明")]),_c('li',{on:{"click":function($event){return _vm.jumpPath('/help?type=2')}}},[_vm._v("常见问题")])]),_vm._m(2),_vm._m(3)]),_vm._m(4)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeBox"},[_c('img',{staticClass:"code",attrs:{"src":require("../assets/code.png"),"alt":"code"}}),_c('p',{staticClass:"codeHint"},[_vm._v("扫一扫关注公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://beian.miit.gov.cn/","rel":"nofollow"}},[_c('div',{staticClass:"Copyright"},[_vm._v(" Copyright © 2021 苏州森石企业管理咨询有限公司 版权所有 "),_c('span',[_vm._v(" 备案号：苏ICP备2021040961号 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("联系方式")]),_c('li',[_vm._v(" 地址：苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室 ")]),_c('li',[_vm._v("电话：19951230862")]),_c('li',[_vm._v("邮箱：kf088@coolsalecloud.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeBox"},[_c('img',{staticClass:"code",attrs:{"src":require("../assets/code.png"),"alt":"code"}}),_c('p',{staticClass:"codeHint"},[_vm._v("扫一扫关注公众号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://beian.miit.gov.cn/","rel":"nofollow"}},[_c('div',{staticClass:"Copyright"},[_vm._v(" Copyright © 2021 森石车链(苏州)信息技术有限公司 版权所有 "),_c('span',[_vm._v(" 备案号：苏ICP备2021040961号 ")]),_c('span',[_c('a',{attrs:{"href":"https://beian.mps.gov.cn/#/query/webSearch?code=32050702011333","rel":"noreferrer","target":"_blank"}},[_vm._v("苏公网安备32050702011333")])])])])
}]

export { render, staticRenderFns }