<template>
  <div class="about">
    <div class="navigationBars">
      <div class="Bars flex_between">
        <img class="back" @click="back" src="@/assets/back.png" alt="" />
      </div>
      <div class="placeholder"></div>
    </div>
    <img class="AboutUsBg" src="@/assets/appBg2.jpg" alt="" />
    <div class="mainNews">
      <div class="Breadcrumb" style="">
        <span style="color: #3d3d3d; font-weight: bold" @click="jumpNews()"
          >新闻中心</span
        >
        /
        <span @click="jumpNews(type)">{{ typeText }}</span>
      </div>
      <div class="newsTile">
        {{ title }}
      </div>
      <div class="dateTime">时间：{{ createTime }} 来源：{{ author }}</div>
      <div v-html="content"></div>
    </div>
  </div>
</template>
  <script>
import config from "@/api/config";
import { getNewsById } from "@/api/api";
export default {
  data() {
    return {
      title: "",
      createTime: "",
      author: "",
      content: "",
      type: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getCipherInfo();
  },
  computed: {
    typeText() {
      if (this.type == 1) {
        return "公司动态";
      } else if (this.type == 2) {
        return "行业资讯";
      } else {
        return "行业详情";
      }
    },
  },
  methods: {
    back() {
      this.$router.replace("/newList");
    },
    formatRichText(html) {
      let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match
          .replace(/style="[^"]+"/gi, "")
          .replace(/style='[^']+'/gi, "");
        match = match
          .replace(/width="[^"]+"/gi, "")
          .replace(/width='[^']+'/gi, "");
        match = match
          .replace(/height="[^"]+"/gi, "")
          .replace(/height='[^']+'/gi, "");
        return match;
      });
      newContent = newContent.replace(
        /style="[^"]+"/gi,
        function (match, capture) {
          match = match
            .replace(/width:[^;]+;/gi, "max-width:100%;")
            .replace(/width:[^;]+;/gi, "max-width:100%;");
          return match;
        }
      );
      newContent = newContent.replace(/<br[^>]*\/>/gi, "");
      newContent = newContent.replace(
        /\<img/gi,
        '<img style="max-width:100%;height:auto;"'
      );
      return newContent;
    },
    //处理富文本框图片没有地址的
    replaceImg(content) {
      var imgInfo = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
      var allImg = content.match(imgInfo); // 取到所有img标签 放到数组allImg里面
      if (allImg) {
        //可能没有匹配
        for (var i = 0; i < allImg.length; i++) {
          var srcImg = allImg[i].replace(imgInfo, "$1"); //取src面的内容
          if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
            //匹配http，https，看需求是否处理
          } else {
            //修改富文本字符串内容 img标签src 相对路径改为绝对路径
            content = content.replace(
              new RegExp(srcImg, "g"),
              config.static_url + srcImg
            ); //config.static_url + srcImg 是替换地址
          }
        }
      }
      return content;
    },
    async getCipherInfo() {
      let xhr = await getNewsById(this.$route.query.newsId);
      if (xhr.code == 200) {
        this.title = xhr.data.newsTitle;
        this.createTime = xhr.data.createTime;
        this.author = xhr.data.author;
        xhr.data.content = this.formatRichText(xhr.data.content);
        this.content = this.replaceImg(xhr.data.content);
        this.type = xhr.data.newsType;
      }
    },
    jumpNews(type) {
      if (type) {
        this.$router.replace("/newList?type=" + type);
      } else {
        this.$router.replace("/newList");
      }
    },
  },
};
</script>
<style scoped lang="less">
@media (max-width: 960px) {
  .navigationBars {
    display: block !important;
  }
}
.Breadcrumb {
  color: #606266;
  font-size: 14px;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
}

.AboutUsBg {
  width: 100%;
  height: 20vw;
  min-height: 100px;
  object-fit: cover;
}
.navigationBars {
  display: none;
  .back {
    width: 30px;
    height: auto;
  }
  .Bars {
    padding: 0 10px;
    background: #ffffff;
    height: 60px;
    position: fixed;
    width: 100%;
    border-bottom: 1px #eeeeee solid;
  }
  .placeholder {
    height: 60px;
  }
}
.mainNews {
  max-width: 810px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px 30px;

  .newsTile {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1;
    line-height: 1.2;
    text-align: center;
    padding: 20px 0;
  }

  .dateTime {
    color: #9a9a9a;
    font-size: 16px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 30px;
  }
}
</style>
    