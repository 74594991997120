import config from "@/api/config";
// 判断是否是手机端
export function isMobile() {
    var userAgentInfo = navigator.userAgent;
 
    var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var mobile_flag = false;
    
      //根据userAgent判断是否是手机
     for (var v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
              mobile_flag = true;
              break;
        }
     }
     var screen_width = window.screen.width;
     var screen_height = window.screen.height;
 
    //根据屏幕分辨率判断是否是手机
    if (screen_width > 325 && screen_height < 750) {
        mobile_flag = true;
    }
 
    return mobile_flag;
 }
 export  function IsPC() {
    let width =  getClientWidth()
    if (width > 980) {
        return true
    } else {
        return false
    }
}
export function getClientWidth(){
    let width = document.documentElement.clientWidth || document.body.clientWidth

    return width
}
export function random(x,y){
  return  Math.round(Math.random()*(y-x)+x)
}

export function uuid() {
    var s = [];
    var hexDigits = "AaBbCcDdEdFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits[random(0,hexDigits.length-1)]||'_';
    }
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
}
export  function IsClientScope(min,max) {
    let width = getClientWidth()
    if (width < min) {
        return false
    } else if(width>max) {
        return false
    }else{
        return true
    }
}
export  function IsPhone() {
    let width = getClientWidth()
    if (width < 750) {
        return true
    } else {
        return false
    }
}
export  function isInView(id) {
    var bottom = document.getElementById(id);
    if(!bottom){
        return false
    }
    // 设备的可视化高度
    var clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // 元素距离顶部的距离
    let bottomTop = bottom.offsetTop;
    // 元素的高度
    let height = bottom.clientHeight;
    let scrollTop = document.documentElement.scrollTop;
    if ((bottomTop + height / 2) - scrollTop <= clientHeight) {
        return true
    } else {
        return false
    }
}

/**
 * 获取元素距离顶部的距离
 * @returns 
 */
export function offsetTop(id){
    var bottom = document.getElementById(id);
    return bottom.offsetTop
}

export function addZore(num){
    if(num<=9){
        return "0"+num
    }else{
        return  num
    }
}


export function formatRichText(html) {
    let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
      match = match
        .replace(/style="[^"]+"/gi, "")
        .replace(/style='[^']+'/gi, "");
      match = match
        .replace(/width="[^"]+"/gi, "")
        .replace(/width='[^']+'/gi, "");
      match = match
        .replace(/height="[^"]+"/gi, "")
        .replace(/height='[^']+'/gi, "");
      return match;
    });
    newContent = newContent.replace(
      /style="[^"]+"/gi,
      function (match, capture) {
        match = match
          .replace(/width:[^;]+;/gi, "max-width:100%;")
          .replace(/width:[^;]+;/gi, "max-width:100%;");
        return match;
      }
    );
    newContent = newContent.replace(/<br[^>]*\/>/gi, "");
    newContent = newContent.replace(
      /\<img/gi,
      '<img style="max-width:100%;height:auto;"'
    );
    return newContent;
  }
  //处理富文本框图片没有地址的
export  function replaceImg(content) {
    var imgInfo = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
    var allImg = content.match(imgInfo); // 取到所有img标签 放到数组allImg里面
    if (allImg) {
      //可能没有匹配
      for (var i = 0; i < allImg.length; i++) {
        var srcImg = allImg[i].replace(imgInfo, "$1"); //取src面的内容
        if (srcImg.slice(0, 4) == "http" || srcImg.slice(0, 5) == "https") {
          //匹配http，https，看需求是否处理
        } else {
          //修改富文本字符串内容 img标签src 相对路径改为绝对路径
          content = content.replace(
            new RegExp(srcImg, "g"),
            config.static_url + srcImg
          ); //config.static_url + srcImg 是替换地址
        }
      }
    }
    return content;
  }