<template>
  <div>
    <div class="header">
      <div class="content flex_start">
        <img class="back" @click="back" src="@/assets/home.png" alt="" />
        <div class="name">酷骁帮助中心</div>
        <div
          @click="check(1)"
          :class="['navItem', type == 1 ? 'navActive' : '']"
        >
          使用说明
        </div>
        <div
          @click="check(2)"
          :class="['navItem', type == 2 ? 'navActive' : '']"
        >
          常见问题
        </div>
      </div>
    </div>
    <div class="headerCopy"></div>
    <div class="content">
      <img class="banner" src="@/assets/appBg.jpg" alt="" />
      <instructions v-if="type == 1"></instructions>
      <questionList v-else></questionList>
    </div>
  </div>
</template>

<script>
import instructions from "./help/instructions.vue";
import questionList from "./help/questionList.vue";
export default {
  data() {
    return {
      type: 2,
    };
  },
  components: {
    instructions,
    questionList,
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  watch: {
    $route() {
      if (this.$route.query.type) {
        this.type = this.$route.query.type;
      }
    },
  },
  methods: {
    back() {
      this.$router.replace("/");
      window.scrollTo(0, 0);
    },
    check(type) {
      this.type = type;
    },
  },
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 750px) {
  .header {
    height: 60px !important;
    .back {
      display: block !important;
      width: 30px !important;
      height: auto;
      margin-right: 30px !important;
    }
    .name {
      font-size: 14px !important;
      line-height: 60px !important;
    }
    .navItem {
      font-size: 12px !important;
      margin-left: 20px !important;
      line-height: 60px !important;
    }
  }
  .headerCopy {
    height: 60px !important;
  }
}
.headerCopy {
  height: 150px;
  margin-bottom: 50px;
}
.header {
  z-index: 3;
  width: 100%;
  height: 150px;
  background: #ffffff;
  box-shadow: 0px 9px 25px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  margin-bottom: 50px;
  .back {
    // display: none;
    width: 35px;
    height: auto;
    margin-right: 10px;
  }

  .name {
    font-size: 36px;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 150px;
  }
  .navItem {
    font-size: 18px;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 150px;
    margin-left: 110px;
    position: relative;
    cursor: pointer;
  }
  .navItem::after {
    content: "";
    height: 5px;
    display: block;
    position: absolute;
    width: 0px;
    transition: all 500ms;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0px;
  }
  .navActive::after {
    width: 100%;
    background: #2477ff;
  }
}
.banner {
  width: 100%;
  height: 150px;
  object-fit: cover;
  display: block;
}
</style>