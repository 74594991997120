<template>
  <div class="customTitle">
    <div class="english" :style="{ color: englishColor }">{{ english }}</div>
    <div class="title" :style="{ color: titleColor }">{{ title }}</div>
    <div class="hint" :style="{ color: hintColor }">{{ hint }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      englishColor: "rgba(127, 127, 127, 0.1)",
      titleColor: "#3d3d3d",
      hintColor: "#7f7f7f",
    };
  },
  created() {
    if (this.type == "white") {
      this.englishColor = "rgba(255,255,255,0.1)";
      this.titleColor = " #FFFFFF";
      this.hintColor = " #FFFFFF";
    }
  },
  props: {
    // white gray
    type: {
      type: String,
      default: "gray",
    },
    english: {
      type: String,
      default: "COOLSELL BUEINESS MODULE",
    },
    title: {
      type: String,
      default: "酷骁的业务模块",
    },
    hint: {
      type: String,
      default: "汽车流通产业链数字科技服务商",
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 750px) {
  .english {
    font-size: 20px !important;
  }
  .customTitle {
    margin-top: 30px !important;
    margin-bottom: 15px !important;
  }
  .title {
    font-size: 20px !important;
    margin-top: -10px !important;
  }
  .hint {
    font-size: 12px !important;
    line-height: 20px !important;
    margin-top: 5px !important;
  }
}
.customTitle {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
}
.english {
  font-size: 36px;
  font-weight: 700;
  color: rgba(127, 127, 127, 0.1);
  line-height: 1;
  word-break: break-all;
  word-wrap: break-word;
}
.title {
  font-size: 36px;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 1;
  margin-top: -17px;
}
.hint {
  font-size: 18px;
  font-weight: 400;
  color: #7f7f7f;
  line-height: 24px;
  margin-top: 10px;
}
</style>