<template>
  <div class="home">
    <banner></banner>
    <div class="content">
      <customTitle
        english="COOLSELLBUEINESSMODULE"
        title="酷骁的业务模块"
        hint="汽车流通产业链数字科技服务商"
      ></customTitle>
      <business></business>
    </div>
    <serveInfo></serveInfo>
    <div class="content">
      <customTitle
        english="COOLSELLNEWFEED"
        title="酷骁的新闻动态"
        hint="汽车流通产业链数字科技服务商"
      ></customTitle>
      <newsChunk></newsChunk>
    </div>
  </div>
</template>

<script>
import banner from "./homeCom/banner.vue";

import business from "./homeCom/business.vue";
import serveInfo from "./homeCom/serveInfo.vue";
import newsChunk from "./homeCom/newsChunk.vue";
export default {
  name: "HomeView",
  components: {
    banner,
    business,
    serveInfo,
    newsChunk,
  },

  methods: {},
};
</script>

<style scoped lang="less">
.home {
  .banner {
    width: 100%;
    height: auto;
    // object-fit: contain;
  }
}
</style>
