<template>
  <div>
    <img class="banner" src="@/assets/connectUs/bg.jpg" alt="" />
    <div class="content">
      <div class="enterprise flex_around">
        <div class="item">
          <img class="icon" src="@/assets/connectUs/1.png" alt="" />
          <div class="name">公司地址</div>
          <div class="hint">
            苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室
          </div>
        </div>
        <div class="item">
          <img class="icon" src="@/assets/connectUs/2.png" alt="" />
          <div class="name">联系方式</div>
          <div class="hint"><span>联系电话：</span>19951230862</div>
        </div>
        <div class="item">
          <img class="icon" src="@/assets/connectUs/3.png" alt="" />
          <div class="name">电子邮箱</div>
          <div class="hint">kf088@coolsalecloud.com</div>
        </div>
        <div class="item">
          <img class="icon" src="@/assets/connectUs/4.png" alt="" />
          <div class="name">官网地址</div>
          <div class="hint">http://www.coolsalecloud.com</div>
        </div>
      </div>
      <customTitle
        english="COOLSELL NEW  FEED"
        title="酷骁的职位招聘"
        hint="汽车流通产业链数字科技服务商"
      ></customTitle>
      <div class="workBox" v-for="item in list" :key="item.jobId">
        <div class="workHeader flex_around">
          <div class="workHeaderLeft">
            <div class="name">
              {{ item.jobName }} <span>{{ item.jobPay }}</span>
            </div>
            <div class="flex_between info">
              <div class="flex_center">
                <img src="@/assets/map.png" alt="map" />
                <div>{{ item.jobPlace }}</div>
              </div>
              <div class="flex_center">
                <img src="@/assets/folder.png" alt="folder" />
                <div>{{ item.jobExperience }}</div>
              </div>
              <div class="flex_center">
                <img src="@/assets/book.png" alt="book" />
                <div>{{ item.jobEducation }}</div>
              </div>
            </div>
            <div class="tags flex_start" v-if="item.jobWelfare">
              <div v-for="(ele, index) in item.jobWelfare" :key="index">
                {{ ele }}
              </div>
              <!-- <div>带薪年假</div>
              <div>定期体检</div>
              <div>节日福利</div> -->
            </div>
          </div>
          <div class="time">招聘时间：{{ item.createTime }}</div>
        </div>
        <div
          class="flex_around"
          style="align-items: flex-start; flex-wrap: wrap"
        >
          <div class="responsibility">
            <div class="name">岗位职责:</div>
            <div class="htmlText" v-html="item.jobContent"></div>
          </div>
          <div class="responsibility">
            <div class="name">岗位要求:</div>
            <div class="htmlText" v-html="item.jobAsk"></div>
          </div>
        </div>
      </div>

      <baidu-map
        id="map"
        :center="center"
        :zoom="zoom"
        class="bm-view"
        @ready="init"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-marker
          :position="center"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
          <bm-label
            content="苏州市相城区高铁新城青龙港路286号长三角国际研发社区启动区2幢1层102室"
            :labelStyle="{ color: '', fontSize: '14px', border: 'none' }"
            :offset="{ width: -75, height: 30 }"
          />
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import { jobOnline } from "@/api/api.js";
export default {
  data() {
    return {
      center: {
        // 120.645771,31.426959
        lng: " 120.633428",
        lat: "31.430643",
      },
      zoom: 30,
      list: [],
    };
  },
  created() {
    this.jobOnline();
  },
  methods: {
    init() {},
    async jobOnline() {
      let xhr = await jobOnline();
      if (xhr.code == 200) {
        this.list = xhr.rows;
      } else {
        this.list = [];
      }
    },
  },
};
</script>

<style scoped lang="less">
#map {
  width: 100%;
  height: 600px;
  margin-bottom: 100px;
  margin-top: 100px;
}
.htmlText {
  font-size: 14px;
  line-height: 1.5;
}
@media only screen and (max-width: 750px) {
  .workBox {
    .responsibility {
      width: 90% !important;
    }
    .workHeaderLeft {
      width: 90% !important;
    }
    .time {
      width: 90% !important;
    }
  }
  .enterprise {
    margin-top: 50px !important;
    .item {
      margin-bottom: 20px;
      width: 40%;
    }
    .name {
      font-size: 18px !important;
    }
    .icon {
      width: 80px !important;
      height: 80px !important;
      margin-bottom: 10px !important;
    }
    .hint {
      margin-bottom: 10px !important;

      span {
        display: block;
      }
    }
  }
}
.banner {
  width: 100%;
  height: auto;
  display: block;
}
.workBox {
  max-width: 1200px;
  min-height: 284px;
  padding-bottom: 50px;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  border-top: #2477ff 10px solid;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 50px;
  padding: 10px;
  .responsibility {
    width: 410px;
    margin-bottom: 23px;

    .name {
      font-size: 18px;
      font-weight: 700;
      color: #3d3d3d;
      line-height: 1.5;
    }
    p {
      font-size: 14px;
      color: #3d3d3d;
      line-height: 1.5;
    }
  }
  .workHeader {
    // border-top: #2477ff 10px solid;
    padding-top: 48px;
    align-items: flex-start;
    flex-wrap: wrap;
    .workHeaderLeft {
      width: 410px;
    }
    .time {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 18px;
      width: 410px;
      text-align: right;
      margin-bottom: 20px;
    }
    .tags {
      flex-wrap: wrap;
      div {
        margin-bottom: 20px;
        margin-right: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 25px;
        text-align: center;
        min-width: 71px;
        padding: 0 8px;
        height: 25px;
        border-radius: 5px;
        opacity: 1;
        border: 1px solid #7f7f7f;
      }
    }
    .name {
      font-size: 30px;
      font-weight: 700;
      color: #2477ff;
      line-height: 1;
      margin-bottom: 20px;
      span {
        font-size: 18px;
        font-weight: 700;
        color: #3d3d3d;
        line-height: 1;
        margin-left: 22px;
      }
    }
    .info {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 18px;
      margin-bottom: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
.enterprise {
  align-items: flex-start;
  margin-top: 100px;
  flex-wrap: wrap;
  .item:hover .icon {
    // transform: scale(0.8);
    transform: rotateY(180deg);
  }
  .item {
    .icon {
      width: 160px;
      height: 160px;
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
      transition: transform 500ms;
    }
    .name {
      font-size: 24px;
      font-weight: 700;
      color: #3d3d3d;
      line-height: 1;
      text-align: center;
    }
    .hint {
      font-size: 18px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 24px;
      max-width: 274px;
      margin-top: 20px;
      text-align: center;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>