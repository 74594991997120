<template>
  <div>
    <!-- <banner></banner> -->
    <div class="bannerBox">
      <img class="banner" src="@/assets/schemeBanner.jpg" alt="" />
      <img class="text" src="@/assets/text.png" alt="" />
    </div>

    <productList></productList>
    <customTitle
      title="酷骁的产品特点"
      english="COOLSELL PRODUCT FAEATURE"
    ></customTitle>
    <div class="schemeBox" style="margin: 0 auto">
      <div class="title">技术赋能 资源重构</div>
      <div class="scheme">
        <div class="flex">
          <p>
            酷晓是一个<span>集客</span>平台，一个<span>集合经销商</span>的平台
          </p>
          <p>酷骁是一个<span>共享库存车资源、共享销售渠</span>道的平台</p>
        </div>
        <div class="flex">
          <p>酷骁是一个与店端<span>不争客户、不争利润</span>的平台</p>
          <p>酷骁是一个汽车流通产业链的<span>赋能平台、服务平台</span></p>
        </div>
      </div>
    </div>
    <painSpot></painSpot>
  </div>
</template>

<script>
import banner from "./homeCom/banner.vue";
import painSpot from "./productCom/painSpot.vue";
import productList from "./productCom/productList.vue";
export default {
  name: "product23",
  components: { banner, painSpot, productList },
  data() {
    return {
      cultureInView2: false,
      cultureInView: false,
    };
  },
  created() {
    console.log("创建产品");
    this.cultureInView2 = false;
    this.cultureInView = false;

    setTimeout(() => {
      this.cultureInView = true;
    }, 200);
    setTimeout(() => {
      this.cultureInView2 = true;
    }, 1200);
  },
};
</script>

<style csoped lang="less">
@media only screen and (max-width: 960px) {
  .schemeBox .scheme {
    font-size: 14px !important;
  }
}
.flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.schemeBox {
  max-width: 1200px;
  margin: 0 auto;
  .title {
    background-image: url("@/assets/schemeBg.png");
    height: 100px;
    background-size: cover;
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    line-height: 100px;
    text-align: center;
  }
  .scheme span {
    color: rgb(221, 113, 79);
  }
  .scheme p:hover {
    transform: translateX(15px);
  }
  .scheme {
    border: 1px solid #2477ff;
    padding: 68px 45px;
    font-size: 20px;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 1.5;
    // min-height: 268px;
    margin-bottom: 20px;
    p {
      transition: all 500ms;
      padding: 10px;
      i {
        padding-right: 5px;
        font-style: normal;
      }
      span {
        color: rgba(241, 113, 24, 1);
        font-weight: 800;
      }
    }
  }
}
</style>

<style scoped>
@media only screen and (max-width: 960px) {
  .bannerBox .text {
    top: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    width: 80%;
  }
  .banner {
    height: 45vw !important;
  }
}
.banner {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.bannerBox {
  position: relative;
}
.bannerBox .text {
  position: absolute;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  /* top: 300px; */
  bottom: 33%;
}
</style>