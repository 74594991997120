import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
// import "@/tool/rem"
import { Carousel, CarouselItem, Drawer} from 'element-ui';
import navList from "@/components/navList.vue";
import footCustom from "@/components/footCustom.vue";
import customTitle from "@/components/title.vue";
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Drawer)
Vue.component("navList",navList)
Vue.component("footCustom",footCustom)
Vue.component("customTitle",customTitle)
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'jPGdTwtXGLjAYnDZIDsXnUXrCOGIdT5S' })
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
