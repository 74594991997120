<template>
  <div>
    <el-carousel height="60vw" v-if="isPhone">
      <el-carousel-item v-for="item in list" :key="item.photo">
        <img class="banner" :src="config.static_url + item.photo" alt="" />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img class="banner" src="@/assets/banner/共享库存资源(1).jpg" alt="" />
      </el-carousel-item> -->
    </el-carousel>
    <el-carousel height="100vh" v-else>
      <el-carousel-item v-for="item in list" :key="item.photo">
        <img class="banner" :src="config.static_url + item.photo" alt="" />
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img class="banner" src="@/assets/banner/共享库存资源(1).jpg" alt="" />
      </el-carousel-item> -->
    </el-carousel>
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
import { getBanner } from "@/api/api";
import config from "@/api/config";
export default {
  data() {
    return {
      isPhone: false,
      config: config,
      list: [],
    };
  },
  created() {
    this.getBanner();
    let that = this;
    that.isPhone = IsClientScope(0, 750);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 750);
    };
    window.addEventListener("resize", watchWindowSize);
  },
  methods: {
    async getBanner() {
      let xhr = await getBanner();
      if (xhr.code == 200) {
        this.list = xhr.rows;
      } else {
        this.list = [];
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>