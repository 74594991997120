var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teamBox"},[(_vm.isPhone)?_c('el-carousel',{attrs:{"height":"240vw","arrow":"never","indicator-position":"none","autoplay":false,"loop":""}},[_c('el-carousel-item',[_c('div',{staticClass:"box1 phoneBox"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"user",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/jia.png"),"alt":""}}),_c('div',[_c('div',{class:[
                'animate__animated',
                _vm.currentPage == 0 ? 'animate__lightSpeedInRight' : '',
              ]},[_c('div',{staticClass:"name"},[_vm._v("贾钧/JIAJUN")]),_c('div',{staticClass:"type"},[_vm._v("创始人")])]),_c('div',{class:[
                'info',
                'animate__animated',
                _vm.currentPage2 == 0 ? 'animate__slideInUp' : '',
              ]},[_c('ul',[_c('li',[_vm._v(" 23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验 ")]),_c('li',[_vm._v("曾任英之杰投资（中国） 区域总监")])])])])])])]),_c('el-carousel-item',[_c('div',{staticClass:"box1 phoneBox"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"user",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/jia.png"),"alt":""}}),_c('div',[_c('div',{class:[
                'animate__animated',
                _vm.currentPage == 2 ? 'animate__lightSpeedInRight' : '',
              ]},[_c('div',{staticClass:"name"},[_vm._v("贾钧/JIAJUN")]),_c('div',{staticClass:"type"},[_vm._v("创始人")])]),_c('div',{class:[
                'info',
                'animate__animated',
                _vm.currentPage2 == 2 ? 'animate__slideInUp' : '',
              ]},[_c('ul',[_c('li',[_vm._v(" 23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验 ")]),_c('li',[_vm._v("曾任英之杰投资（中国） 区域总监")])])])])])])])],1):_c('el-carousel',{attrs:{"height":"725px","arrow":"never","indicator-position":"none","autoplay":false,"loop":""}},[_c('el-carousel-item',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"flex_around content"},[_c('div',[_c('div',{class:[
                'animate__animated',
                _vm.currentPage == 0 ? 'animate__lightSpeedInRight' : '',
              ]},[_c('div',{staticClass:"name"},[_vm._v("贾钧/JIAJUN")]),_c('div',{staticClass:"type"},[_vm._v("创始人")])]),_c('div',{class:[
                'info',
                'animate__animated',
                _vm.currentPage2 == 0 ? 'animate__slideInUp' : '',
              ]},[_c('ul',[_c('li',[_vm._v(" 23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验 ")]),_c('li',[_vm._v("曾任英之杰投资（中国） 区域总监")])])])]),_c('img',{staticClass:"user",attrs:{"src":require("@/assets/jia.png"),"alt":""}})])])]),_c('el-carousel-item',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"flex_around content"},[_c('div',[_c('div',{class:[
                'animate__animated',
                _vm.currentPage == 2 ? 'animate__lightSpeedInRight' : '',
              ]},[_c('div',{staticClass:"name"},[_vm._v("贾钧/JIAJUN")]),_c('div',{staticClass:"type"},[_vm._v("创始人")])]),_c('div',{class:[
                'info',
                'animate__animated',
                _vm.currentPage2 == 2 ? 'animate__slideInUp' : '',
              ]},[_c('ul',[_c('li',[_vm._v(" 23年企业经营管理、14年汽车商贸企业高管，6年企业数据化运营管理经验 ")]),_c('li',[_vm._v("曾任英之杰投资（中国） 区域总监")])])])]),_c('img',{staticClass:"user",attrs:{"src":require("@/assets/jia.png"),"alt":""}})])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }