<template>
  <div class="foldDown">
    <div class="flex" @click="isOpen = !isOpen">
      <slot></slot>
      <img
        v-if="height"
        src="@/assets/downFold.png"
        :class="['downFold', isOpen ? 'active' : '']"
        alt=""
      />
    </div>
    <div
      class="foldDownBox"
      :style="{ height: isOpen ? height + 'px' : '0px' }"
    >
      <div :id="id">
        <slot name="fold"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { uuid } from "@/tool/index";
export default {
  data() {
    return {
      height: 100,
      isOpen: false,
      id: "",
    };
  },
  created() {
    this.id = uuid();
  },
  methods: {
    init() {
      let dom = document.getElementById(this.id);
      if (dom) {
        this.height = dom.clientHeight;
      }
      this.isOpen = false;
    },
  },
  mounted() {
    this.init();

    setTimeout(() => {
      this.init();
    }, 1000);
  },
};
</script>

<style scoped lang="less">
.active {
  transform: rotate(0deg) !important;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.foldDownBox {
  width: 100%;
  overflow: hidden;
  transition: height 500ms;
}
.downFold {
  width: 12px;
  height: 12px;
  transition: all 500ms;
  transform: rotate(-90deg);
}
</style>