<template>
  <div style="margin-bottom: 80px">
    <customTitle
      english="COOLSELLADDRESSESINDUSTRYPAINPOINTS"
      title="酷骁解决的行业痛点"
    ></customTitle>
    <div
      class="content flex_around"
      style="align-items: flex-start; flex-wrap: wrap"
    >
      <div class="schemeBox">
        <div class="title">4S店的解决方案</div>
        <div class="scheme">
          <p>
            <i>01</i>
            <span>提升销量</span>
            增加销售渠道，实现跨时间、跨地点、跨品牌销售战略。
          </p>
          <p>
            <i>02</i>
            <span>提升利润</span>
            盘活资金，盘活新老客户，销价逢高销售，增加衍生利润。
          </p>
          <p>
            <i>03</i>
            <span>降低库存</span>
            滞销车流动加快，库位周转加快，增加畅销车库存。
          </p>
          <p>
            <i>04</i>
            <span>降低成本</span>
            长库龄车周转加快，财务利息、仓储费用下降，盘活资金。
          </p>
        </div>
      </div>
      <div class="schemeBox">
        <div class="title">经销商的解决方案</div>
        <div class="scheme">
          <p>
            <i>01</i>
            <span style="color: rgba(36, 119, 255, 1)">找车无忧</span>
            平台提供海量认证真车源，找车效率高、成本低 。
          </p>
          <p>
            <i>02</i>
            <span style="color: rgba(36, 119, 255, 1)">交易无忧</span>
            平台携手银行提供监管账户功能，全流程保障。
          </p>
          <p>
            <i>03</i>
            <span style="color: rgba(36, 119, 255, 1)">融资无忧</span>
            平台携手银行提供供应链金融，解决资金难题。
          </p>
          <p>
            <i>04</i>
            <span style="color: rgba(36, 119, 255, 1)">运车无忧</span>
            平台携手专业物流和保险，提供运车和保险服务。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.schemeBox {
  width: 580px;
  .title {
    background-image: url("@/assets/schemeBg.png");
    height: 100px;
    background-size: cover;
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    line-height: 100px;
    text-align: center;
  }
  .scheme p:hover {
    transform: translateX(15px);
  }
  .scheme {
    border: 1px solid #2477ff;
    padding: 68px 45px;
    font-size: 14px;
    font-weight: 400;
    color: #3d3d3d;
    line-height: 1.5;
    // min-height: 268px;
    margin-bottom: 20px;
    p {
      transition: all 500ms;
      padding: 10px;
      i {
        padding-right: 5px;
        font-style: normal;
      }
      span {
        color: rgba(241, 113, 24, 1);
        font-weight: 800;
      }
    }
  }
}
</style>