<template>
  <div>
    <img class="banner" src="@/assets/connectUs/bg.jpg" alt="" />
    <div class="pc">
      <brightSpot></brightSpot>
      <appCode></appCode>
    </div>
    <div class="phone">
      <appCode></appCode>
      <brightSpot></brightSpot>
    </div>
  </div>
</template>

<script>
import brightSpot from "@/views/appCom/brightSpot.vue";
import appCode from "./appCom/appCode.vue";
export default {
  components: {
    brightSpot,
    appCode,
  },
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 750px) {
  .banner {
    display: block !important;
  }
  .pc {
    display: none !important;
  }
  .phone {
    display: block !important;
  }
}
.banner {
  width: 100%;
  height: auto;
  display: none;
}
.pc {
  display: block;
}
.phone {
  display: none;
}
</style>