<template>
  <div>
    <!-- 业务模块 -->
    <div v-if="isPhone" class="phoneBox">
      <div class="listBox">
        <div
          class="flex_around list"
          v-for="(chunk, index) in list"
          :key="index"
        >
          <div
            class="item"
            v-for="item in chunk"
            :key="item.id"
            @click="check(item.id)"
          >
            <div class="chunk">
              <img :src="item.icon" class="icon" alt="icon" />
              <p class="name">{{ item.name }}</p>
            </div>
            <div
              :class="[
                'floatingBox',
                activeIndex === item.id ? 'floatingBoxActive' : '',
              ]"
            >
              <img :src="item.icon" class="icon" alt="icon" />
              <p class="name">{{ item.name }}</p>
              <p class="hint">{{ item.hint }}</p>
            </div>
          </div>
        </div>
      </div>

      <img src="@/assets/business.png" alt="business" class="business" />
    </div>
    <div v-else class="pcBox flex_center">
      <img src="@/assets/business.png" alt="business" class="business" />
      <div>
        <div
          class="flex_around list"
          v-for="(chunk, index) in list"
          :key="index"
        >
          <div class="item" v-for="item in chunk" :key="item.id">
            <div class="chunk">
              <img :src="item.icon" class="icon" alt="icon" />
              <p class="name">{{ item.name }}</p>
              <p class="hint">{{ item.hint }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  data() {
    return {
      isPhone: false,
      activeIndex: -1,
      activeTime: undefined,

      list: [
        [
          {
            icon: require("@/assets/iconBusiness/1.png"),
            name: "一键找车",
            hint: "智能搜索，海量真车源促成交易轻轻松松。",
            id: 11,
          },
          {
            icon: require("@/assets/iconBusiness/2.png"),
            name: "二手车",
            hint: "精品二手车，买车卖车尽享便利。",
            id: 12,
          },
          {
            icon: require("@/assets/iconBusiness/3.png"),
            name: "需求挂单",
            hint: "发布需求，一个需求多店响应，迅速成交。",
            id: 13,
          },
        ],
        [
          {
            icon: require("@/assets/iconBusiness/4.png"),
            name: "促销会",
            hint: "商家钜惠返利，优惠惊喜不断。",
            id: 14,
          },
          {
            icon: require("@/assets/iconBusiness/5.png"),
            name: "特卖会",
            hint: "品牌特卖会，区域特卖会免费线上办车展。",
            id: 15,
          },
          {
            icon: require("@/assets/iconBusiness/6.png"),
            name: "酷骁帮您",
            hint: "酷骁帮您，多种衍生服务惠及产业链每一端。",
            id: 16,
          },
        ],
        [
          {
            icon: require("@/assets/iconBusiness/7.png"),
            name: "积分商城",
            hint: "会员积分累计，激情换购，超值享受。",
            id: 17,
          },
          {
            icon: require("@/assets/iconBusiness/8.png"),
            name: "运车服务",
            hint: "运车服务，随时为您呈送快捷与放心。",
            id: 18,
          },
          {
            icon: require("@/assets/iconBusiness/9.png"),
            name: "保险服务",
            hint: "保险服务，守护您的出行安全。",
            id: 19,
          },
        ],
      ],
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 960);
    };
    window.addEventListener("resize", watchWindowSize);
  },
  methods: {
    check(index) {
      this.activeIndex = index;
      clearTimeout(this.activeTime);
      let that = this;
      this.activeTime = setTimeout(() => {
        // that.activeIndex = -1;
        this.$set(this, "activeIndex", -1);
        console.log(1111111111);
        // clearTimeout(this.activeTime);
      }, 3000);
    },
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
};
</script>

<style scoped lang="less">
@media only screen and (min-width: 750px) and (max-width: 960px) {
  .list {
    margin-bottom: 20px !important;
  }
}
.phoneBox {
  background: #f3f6fa;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  .business {
    width: 80%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
  .listBox {
    position: relative;
    padding-top: 20px;
  }
  .list {
    flex-wrap: wrap;
  }

  // .item:hover .floatingBox {
  //   width: 182px;
  //   height: 182px;
  // }
  .floatingBoxActive {
    width: 182px !important;
    height: 182px !important;
  }
  .floatingBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 0px;
    height: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(36, 119, 255, 0.5);
    border-radius: 10px;
    overflow: hidden;
    transition: all 500ms;
    padding-top: 20px;
    z-index: 1;
    .name {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      margin-top: 15px;
      margin-bottom: 16px;
      text-align: center;
    }
    .hint {
      width: 122px;
      font-size: 12px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 16px;
      margin: 0 auto;
      // text-align: center;
    }
    .icon {
      width: 44px;
      height: 44px;
      display: block;
      margin: 0 auto;
    }
  }
  .chunk {
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 25px;
      text-align: center;
    }
    .icon {
      width: 44px;
      height: 44px;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }
  }
}
.pcBox {
  min-width: 960px;
  width: 100%;
  height: 746px;
  background: #f3f6fa;
  border-radius: 10px;
  .list {
    flex-wrap: wrap;
    min-width: 510px;
    max-width: 570px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    .item {
      cursor: pointer;
    }
    .item:nth-child(3n + 3) .chunk {
      margin-right: 0px !important;
    }
    .item:hover .chunk {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(36, 119, 255, 0.502);
      transform: scale(1.2);
    }

    // .floatingBoxActive {
    //   width: 282px;
    //   height: 282px;
    // }
    .chunk {
      // margin-right: 106px;
      width: 165px;
      height: 165px;
      // background-color: #f3f6fa;
      border-radius: 10px;
      padding-top: 20px;
      transition: all 500ms;
      .name {
        font-size: 14px;
        font-weight: 800;
        color: #3d3d3d;
        line-height: 1;
        margin-top: 5px;
        margin-bottom: 6px;
        text-align: center;
      }
      .hint {
        width: 122px;
        font-size: 12px;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 16px;
        margin: 0 auto;
        text-align: left;
      }
      .icon {
        width: 55px;
        height: 55px;
        object-fit: contain;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .business {
    width: 376px;
    height: auto;
    margin-right: 30px;
  }
}
</style>