<template>
  <div>
    <img class="banner" src="@/assets/appBg2.jpg" alt="" />
    <div class="content">
      <div class="typeList flex_start">
        <div @click="check()" :class="{ active: !type }">全部新闻</div>
        <div @click="check(1)" :class="{ active: type == 1 }">公司动态</div>
        <div @click="check(2)" :class="{ active: type == 2 }">行业资讯</div>
      </div>

      <div class="newsList">
        <div
          class="newsBox flex_start"
          v-for="item in tableData"
          :key="item.id"
          @click="jumpNewsInfo(item)"
        >
          <div class="newsAvaterBox">
            <img
              class="newsAvater"
              :src="config.static_url + item.cover"
              alt=""
            />
          </div>
          <!-- <div class="time timeCenter">
            <div>{{ item.day }}</div>
            <div>{{ item.month }}</div>
          </div> -->
          <div class="time timeCenter">
            <div>{{ item.year }}</div>
            <div>{{ item.month }}</div>
          </div>
          <div class="newsInfo">
            <div class="flex_start">
              <!-- <div class="time timeTitle">
                <div>{{ item.day }}</div>
                <div>{{ item.month }}</div>
              </div> -->
              <div class="time timeTitle">
                <div>{{ item.year }}</div>
                <div>{{ item.month }}</div>
              </div>
              <h4>{{ item.newsTitle }}</h4>
            </div>
            <div class="text">
              {{ item.intro }}
            </div>
            <div class="flex_end lookInfo">
              <div>查看详情</div>
              <img class="more" src="@/assets/more2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <paging @tap="pagFn" :pages="pageIndex" :total="totalPage"></paging>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import paging from "@/components/paging.vue";
import { getList, getNewsById } from "@/api/api";
import config from "@/api/config";
import { addZore } from "@/tool";
export default {
  data() {
    return {
      type: undefined,
      totalPage: 10,
      tableData: [],
      pageSize: 10,
      pageIndex: 1,
      config: config,
    };
  },
  components: {
    paging,
  },
  computed: {
    ...mapState(["page"]),
  },
  created() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    } else {
      this.type = undefined;
      if (this.page && this.page.param) {
        this.type = this.page.param.type;
      }
    }
    this.pageIndex = 1;
    this.getList();
  },
  watch: {
    page(e) {
      if (e && e.param) {
        this.type = e.param.type;
        this.pageIndex = 1;
        this.getList();
      }
    },
    $route() {
      if (this.$route.query.type) {
        this.type = this.$route.query.type;
        this.pageIndex = 1;
        this.getList();
      }
    },
  },
  methods: {
    async jumpNewsInfo(row) {
      if (row.contentType == 1) {
        this.$router.push("/newsInfo?newsId=" + row.newsId);
      } else {
        let xhr = await getNewsById(row.newsId);
        if (xhr.code == 200) {
          window.open(xhr.data.content);
        }
      }
    },
    pagFn(e) {
      if (e == "prev") {
        this.prevFn();
      } else if (e == "next") {
        this.nextFn();
      } else if (e == "first") {
        this.pageIndex = 1;
        this.getList();
      } else if (e == "end") {
        this.pageIndex = this.totalPage;
        this.getList();
      }
    },
    check(type) {
      this.type = type;
      this.pageIndex = 1;
      this.getList();
    },
    prevFn() {
      if (this.pageIndex <= 1) {
        return;
      }
      this.pageIndex--;
      this.getList();
    },
    nextFn() {
      if (this.pageIndex >= this.totalPage) {
        return;
      }
      this.pageIndex++;
      this.getList();
    },
    async getList() {
      let xhr = await getList({
        isShow: 1,
        newsType: this.type,
        pageSize: this.pageSize,
        page: this.pageIndex,
      });
      if (xhr.code == 200) {
        xhr.data.list.forEach((element) => {
          let date = new Date(element.createTime.replace(/-/g, "/"));
          element.day = addZore(date.getDate());
          element.year = addZore(date.getFullYear());
          element.month =
            addZore(date.getMonth() + 1) + "-" + addZore(date.getDate());
        });
        this.tableData = xhr.data.list;
        this.totalPage = xhr.data.pages;
      }
      // xhr.data.list.sort((a, b) => {
      //   let dateA = new Date(a.createTime.replace(/-/g, "/"));
      //   let dateB = new Date(b.createTime.replace(/-/g, "/"));
      //   return dateB.getTime() - dateA.getTime();
      // });
    },
  },
};
</script>

<style scoped lang="less">
@media only screen and (min-width: 750px) and (max-width: 960px) {
  .newsBox {
    .newsAvaterBox {
      width: 328px !important;
      height: 200px !important;
    }
    .time {
      margin-left: 40px !important;
      margin-right: 30px !important;
      div:nth-child(1) {
        font-size: 18px !important;
      }
      div:nth-child(2) {
        font-size: 20px !important;
      }
    }
    .newsInfo {
      h4 {
        font-size: 24px !important;
      }
      .text {
        font-size: 14px !important;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .typeList {
    margin-top: -32px !important;
    margin-bottom: 25px !important;

    div {
      cursor: none !important;
      font-size: 14px !important;
      margin: 0 20px !important;
    }
  }
  .newsBox {
    display: block !important;
    padding: 30px 0 !important;
    border-bottom-width: 1px !important;
    .lookInfo {
      margin-top: 15px !important;
    }
    .newsAvaterBox {
      width: 80vw !important;
      height: 48vw !important;
      margin: 0 auto;
    }
    .timeTitle {
      display: block !important;
    }
    .timeCenter {
      display: none !important;
    }
    .time {
      margin-left: 0px !important;
      margin-right: 15px !important;
      div:nth-child(1) {
        font-size: 14px !important;
      }
      div:nth-child(2) {
        font-size: 22px !important;
      }
    }
    .newsInfo {
      width: 80vw !important;
      margin: 0 auto;
      h4 {
        font-size: 20px !important;
        flex: 1;
      }
      .text {
        font-size: 14px !important;
        margin-top: 15px !important;
      }
    }
  }
}

.banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}
.newsList .newsBox:last-child {
  border-bottom: none;
}
.newsBox {
  align-items: flex-start;
  padding: 50px 0;
  border-bottom: 2px dashed #7f7f7f;
  .lookInfo {
    font-size: 14px;
    font-weight: 400;
    color: #2477ff;
    line-height: 18px;
    margin-top: 20px;
    cursor: pointer;
    img {
      width: 16px;
      height: auto;
      margin-left: 12px;
    }
  }
  .newsInfo {
    flex: 1;

    h4 {
      font-size: 30px;
      font-weight: 700;
      color: #3d3d3d;
      line-height: 1.5;
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      color: #7f7f7f;
      line-height: 1.5;
      margin-top: 20px;
    }
  }
  .newsAvaterBox {
    width: 409px;
    height: 250px;
    overflow: hidden;
    .newsAvater:hover {
      transform: scale(1.1);
    }
    .newsAvater {
      transition: all 0.5s;
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .timeTitle {
    display: none;
  }
  .time {
    margin-left: 60px;
    margin-right: 40px;
    div:nth-child(1) {
      font-size: 28px;
      font-weight: 400;
      color: #2477ff;
      text-align: right;
    }
    div:nth-child(2) {
      font-size: 32px;
      font-weight: 400;
      color: #3d3d3d;
      text-align: right;
    }
  }
}
.typeList {
  margin-top: -38px;
  margin-bottom: 50px;
  div {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    line-height: 1.5;
    margin: 0 52px;
    padding-bottom: 10px;
    border-bottom: rgba(0, 0, 0, 0) solid 4px;
    transition: all 300ms;
    cursor: pointer;
  }
  .active {
    border-bottom: #f17118 solid 4px;
  }
}
</style>