<template>
  <!-- 竖向 -->
  <div
    v-if="isPhone"
    class="timeLineBoxPhone"
    :style="{ height: lineHeight + intervalL + 'px' }"
  >
    <div class="line" :style="{ height: lineHeight + 'px' }">
      <template v-for="(item, index) in variableListPhone">
        <div
          :style="{ top: item.top + 'px' }"
          :class="['dot', activeIndex == index ? 'dotActive' : '']"
          :key="item.id + 'dot'"
          @click="check(index)"
        >
          <div class="dotIn"></div>
        </div>
        <div
          :style="{ top: item.top + 'px' }"
          class="time"
          :key="item.id + 'time'"
          @click="check(index)"
        >
          {{ item.date }}
        </div>
        <div
          :style="{ top: item.top + 30 + 'px' }"
          :class="['hint', activeIndex == index ? 'hintActive' : '']"
          :key="item.id + 'hint'"
        >
          <div class="arrow"></div>
          {{ item.text }}
        </div>
      </template>
    </div>
  </div>
  <!-- 横向 -->
  <div
    v-else
    class="timeLineBoxPc"
    :style="{ padding: maxItemHeight + 100 + 'px 0px' }"
  >
    <div class="line" :id="id">
      <template v-for="(item, index) in variableList">
        <div
          :style="{ left: item.left + 'px' }"
          :class="['dot', activeIndex == index ? 'dotActive' : '']"
          :key="item.id + 'dot'"
          @click="check(index)"
        >
          <div class="dotIn"></div>
        </div>
        <div
          :style="{ left: item.left + 'px' }"
          class="time"
          :key="item.id + 'time'"
          @click="check(index)"
        >
          {{ item.date }}
        </div>
        <div
          :style="{ left: item.left + 'px', width: intervalL * 2 + 'px' }"
          :class="['hint', activeIndex == index ? 'hintActive' : '']"
          :key="item.id + 'hint'"
        >
          <div class="arrow"></div>
          <ul>
            <li
              style="list-style-type;: disc"
              v-for="(text, textIndex) in item.textList"
              :key="item.id + '' + textIndex"
            >
              {{ text }}
            </li>
          </ul>
          <!-- <span v-html="item.text"></span> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { uuid } from "@/tool";
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  extends: "",
  props: {
    list: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },
  data() {
    return {
      id: "",
      intervalL: 10,

      //   pc端用到的信息
      variableList: [],

      isPhone: false,
      maxItemHeight: 100,
      activeIndex: 2,
      circulationTime: null,
      initTime: null,

      //   手机端用到的信息
      lineHeight: 100,
      variableListPhone: [],
    };
  },
  created() {
    this.id = uuid();
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 960);
      that.initVariable();
    };
    window.addEventListener("resize", watchWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
  mounted() {
    this.initVariable();
  },
  watch: {
    list() {
      this.initVariable();
    },
  },

  methods: {
    // 循环播放
    circulationFn() {
      clearInterval(this.circulationTime);
      this.circulationTime = setInterval(() => {
        this.activeIndex++;
        let max = this.isPhone
          ? this.variableListPhone.length
          : this.variableList.length;
        if (this.activeIndex >= max) {
          this.activeIndex = 0;
        }
      }, 3000);
    },
    // 选择活动状态
    check(index) {
      this.activeIndex = index;
      this.circulationFn();
    },
    //
    initVariable() {
      clearTimeout(this.initTime);
      this.initTime = setTimeout(() => {
        if (this.isPhone) {
          this.initVariableVertical();
        } else {
          this.initVariableRows();
        }
      }, 500);
    },
    //
    // 计算竖向的时间轴信息信息
    initVariableVertical() {
      this.intervalL = 110;
      let intervalL = this.intervalL;
      this.variableListPhone = [];
      this.list.forEach((element, index) => {
        this.variableListPhone.push({
          top: index * intervalL + intervalL / 2,
          ...element,
          id: uuid(),
        });
      });
      this.lineHeight = intervalL * this.variableListPhone.length;
      this.circulationFn();
    },
    // 计算横向时间轴信息信息
    initVariableRows() {
      let dom = document.getElementById(this.id);
      if (!dom) {
        return;
      }
      let intervalL = (dom.clientWidth - 400) / (this.list.length - 1);
      this.intervalL = intervalL;
      let start = 200;
      this.variableList = [];
      this.list.forEach((element, index) => {
        this.variableList.push({
          left: index * intervalL + start,
          ...element,
          id: uuid(),
        });
      });
      setTimeout(() => {
        let itemDomList = document.getElementsByClassName("hint");
        if (itemDomList.length == 0) {
          return;
        }
        let maxItemHeight = 100;
        for (let index = 0; index < itemDomList.length; index++) {
          const element = itemDomList[index];
          if (element.clientHeight > maxItemHeight) {
            maxItemHeight = element.clientHeight;
          }
        }
        this.maxItemHeight = maxItemHeight;
        this.circulationFn();
      }, 500);
    },
  },
};
</script>

<style scoped lang="less">
.timeLineBoxPhone {
  position: relative;
  .line {
    width: 4px;
    height: 100%;
    background: #7f7f7f;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      width: 15px;
      height: 15px;
      background: #7f7f7f;
      position: absolute;
      border-radius: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);

      transition: all 800ms;
      .dotIn {
        width: 15px;
        height: 15px;
        background: #7f7f7f;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all 800ms;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .dotActive {
      background: rgba(37, 146, 254, 0.2);
      width: 50px;
      height: 50px;
      .dotIn {
        background: rgba(37, 146, 254, 1);
      }
    }
    .time {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 32px;
      transform: translateY(-50%);
      width: calc(50vw - 50px);
      // display: inline-block;
      position: absolute;
      //   min-width: 180px;
      text-align: left;

      left: 33px;
      right: auto;
    }
    .time:nth-child(2n + 2) {
      right: 33px;
      left: auto;
      text-align: right !important;
    }
    .hintActive {
      opacity: 1 !important;
    }
    .hint {
      transition: all 800ms;
      width: calc(50vw - 50px);
      background: #2477ff;
      position: absolute;
      left: auto;
      right: 33px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.5;
      padding: 16px 18px;
      opacity: 0;
      z-index: 3;
      .arrow {
        position: absolute;
        background: #2477ff;
        width: 10px;
        height: 10px;
        left: auto;
        right: 10%;

        top: 0;
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
      }
    }
    .hint:nth-child(2n + 2) {
      left: 33px;
      right: auto;
      .arrow {
        left: 10%;
        right: auto;
        // top: 0;
        // bottom: auto;
        // transform: translateY(-50%) translateX(-50%) rotate(45deg);
      }
    }
  }
}
.timeLineBoxPc {
  padding-top: 300px;
  .line {
    width: 100%;
    //   width: 1200px;
    height: 4px;
    background: #7f7f7f;
    position: relative;
    .dot {
      width: 15px;
      height: 15px;
      background: #7f7f7f;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);

      transition: all 800ms;
      .dotIn {
        width: 15px;
        height: 15px;
        background: #7f7f7f;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all 800ms;
        transform: translateX(-50%) translateY(-50%);
      }
    }
    .dotActive {
      background: rgba(37, 146, 254, 0.2);
      width: 50px;
      height: 50px;
      .dotIn {
        background: rgba(37, 146, 254, 1);
      }
    }
    .time {
      font-size: 24px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 32px;
      transform: translateX(-50%);

      // display: inline-block;
      position: absolute;
      min-width: 180px;
      text-align: center;
      top: 43px;
      bottom: auto;
    }
    .time:nth-child(2n + 2) {
      bottom: 43px;
      top: auto;
    }
    .hintActive {
      opacity: 1 !important;
    }
    .hint {
      transition: all 800ms;
      width: 290px;
      //   min-height: 128px;
      background: #2477ff;
      position: absolute;
      transform: translateX(-50%);
      top: auto;
      bottom: 93px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.5;
      padding: 16px 18px;
      padding-left: 30px;
      opacity: 0;
      .arrow {
        position: absolute;
        background: #2477ff;
        width: 10px;
        height: 10px;
        left: 50%;
        top: auto;
        bottom: 0;
        transform: translateY(50%) translateX(-50%) rotate(45deg);
      }
    }
    .hint:nth-child(2n + 2) {
      top: 93px;
      bottom: auto;
      .arrow {
        top: 0;
        bottom: auto;
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
      }
    }
  }
}
</style>