<template>
  <div>
    <customTitle
      english="COOLSELLAPPDOWNLOAD"
      title="酷骁真车源APP下载"
    ></customTitle>
    <div class="appCodeList content flex_around">
      <div class="appCode">
        <div class="top">
          <img src="@/assets/app/AndroidIcon.png" class="icon" alt="" />
          <p>适用于各种Android系统</p>
        </div>
        <div class="bottom">
          <p>Android下载安装包</p>
          <p>扫一扫急速体验</p>
        </div>
        <img class="codeImg" src="@/assets/app/icon/AndroidCode.png" alt="" />
      </div>
      <div class="appCode">
        <div class="top">
          <img src="@/assets/app/iosIcon.png" class="icon" alt="" />
          <p>适用于iOS系统</p>
        </div>
        <div class="bottom">
          <p>iOS下载安装包</p>
          <p>扫一扫急速体验</p>
        </div>
        <img class="codeImg" src="@/assets/app/iosCode.png" alt="" />
      </div>
      <div class="appCode">
        <div class="top">
          <img src="@/assets/app/miniIcon.png" class="icon" alt="" />
          <p>微信接入不占内存</p>
        </div>
        <div class="bottom">
          <p>进入小程序</p>
          <p>扫一扫急速体验</p>
        </div>
        <img class="codeImg" src="@/assets/app/miniCode.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped lang="less">
.appCodeList {
  flex-wrap: wrap;
  margin-bottom: 100px;
  .appCode:hover .codeImg {
    transform: translateX(-50%) translateY(-50%) scale(1.1);
    border: rgba(37, 146, 254, 0.2) solid 10px;
  }
  .appCode {
    width: 368px;
    height: 470px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    margin-bottom: 20px;
    .codeImg {
      position: absolute;
      width: 175px;
      height: 175px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: all 500ms;
      border-radius: 10px;
    }
    .bottom {
      text-align: center;
      p:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
        color: #3d3d3d;
        line-height: 24px;
        margin-top: 138px;
      }
      p:nth-child(2) {
        font-size: 18px;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 24px;
        margin-top: 10px;
      }
    }
    .top {
      width: 100%;
      height: 235px;
      background: #2477ff;
      padding-top: 44px;
      p {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        margin-top: 15px;
      }
      .icon {
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }
}
</style>