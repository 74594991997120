<template>
  <div class="flex_around pcNews" v-if="tableData.length > 0">
    <div style="max-width: 562px; width: 100%">
      <el-carousel
        :height="isPhone ? '70vw' : '482px'"
        indicator-position="none"
      >
        <el-carousel-item v-for="item in tableData" :key="item.id">
          <div
            class="carouselBox"
            style="width: 100%; height: 100%"
            @click="jumpNewsInfo(item)"
          >
            <img
              class="newsAvater"
              :src="config.static_url + item.cover"
              alt=""
            />
            <div class="newsInfo flex_between">
              <div>
                <p class="time">{{ item.date }}</p>
                <p class="name">{{ item.newsTitle }}</p>
              </div>
              <img class="moreIcon" src="@/assets/moreFFF.png" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="newsList">
      <div class="lookMore flex_end" @click="lookMore">
        <span class="moreText">查看更多</span>
        <img class="moreIcon moreIcon1" src="@/assets/more.png" alt="" />
        <img class="moreIcon moreIcon2" src="@/assets/more2.png" alt="" />
      </div>
      <div
        class="newsItem flex_between"
        v-for="item in tableData"
        :key="item.id"
        @click="jumpNewsInfo(item)"
      >
        <div class="time">
          <div>{{ item.year }}</div>
          <div>{{ item.month }}</div>
        </div>
        <div class="titleAndInfo">
          <div>{{ item.newsTitle }}</div>
          <div class="ellipsisThree">
            {{ item.intro }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>----暂无新闻----</div>
</template>

<script>
import { getList, getNewsById } from "@/api/api";
import { IsClientScope, addZore } from "@/tool/index";
import config from "@/api/config";
let watchWindowSize;
export default {
  data() {
    return {
      isPhone: false,
      tableData: [],
      config: config,
    };
  },

  methods: {
    lookMore() {
      window.scrollTo(0, 0);
      this.$router.push("/newList");
    },
    async jumpNewsInfo(row) {
      if (row.contentType == 1) {
        this.$router.push("/newsInfo?newsId=" + row.newsId);
      } else {
        let xhr = await getNewsById(row.newsId);
        if (xhr.code == 200) {
          window.open(xhr.data.content);
        }
      }
    },
    async getList() {
      let xhr = await getList({
        pageSize: 3,
        page: 1,
        isRecommend: 1,
      });
      if (xhr.code == 200) {
        xhr.data.list.forEach((element) => {
          let date = new Date(element.createTime.replace(/-/g, "/"));
          element.day = addZore(date.getDate());
          element.date = `${date.getFullYear()}-${addZore(
            date.getMonth() + 1
          )}-${addZore(date.getDate())}`;
          element.month = `${addZore(date.getMonth() + 1)}-${addZore(
            date.getDate()
          )}`;
          element.year = date.getFullYear();
        });
        this.tableData = xhr.data.list;
      }
    },
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 750);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 750);
    };
    window.addEventListener("resize", watchWindowSize);
    this.getList();
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 750px) {
  .menu {
    width: 60px !important;
  }
  .time {
    width: auto !important;
    margin-right: 10px !important;
    text-align: center !important;
  }
  .lookMore {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .titleAndInfo {
    width: auto !important;
    flex: 1 !important;
    div:nth-child(1) {
      font-size: 16px !important;
      font-weight: 700;
      color: #3d3d3d;
    }
    div:nth-child(2) {
      font-size: 12px !important;
      font-weight: 400;
      color: #7f7f7f;
    }
  }
}
.empty {
  text-align: center;
  padding-bottom: 30px;
  font-size: 18px;
  color: #cccccc;
}
.pcNews {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 20px;
  .newsItem:hover {
    transform: scale(0.95);
  }
  .newsItem {
    transition: all 0.5s;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px dashed #7f7f7f;
    .titleAndInfo {
      width: 432px;
      div:nth-child(1) {
        font-size: 24px;
        font-weight: 700;
        color: #3d3d3d;
        line-height: 1.5;
      }
      div:nth-child(2) {
        font-size: 18px;
        font-weight: 400;
        color: #7f7f7f;
        line-height: 1.5;
      }
    }
    .time {
      font-weight: 400;
      color: #2477ff;
      text-align: right;
      margin-right: 36px;

      div:nth-child(1) {
        font-size: 18px;
      }
      div:nth-child(2) {
        font-size: 24px;
      }
    }
  }
  .carouselBox {
    position: relative;
    cursor: pointer;
    .newsAvater:hover {
      transform: scale(1.1);
    }
    .newsAvater {
      transition: all 0.5s;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .newsInfo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 30px;
      background-color: rgba(36, 119, 255, 0.7);
      height: 94px;
      .time {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
      .name {
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
        line-height: 24px;
        margin-top: 5;
      }

      .moreIcon {
        width: 36px;
        height: 36px;
      }
    }
  }
  .lookMore:hover .moreText {
    color: #2477ff !important;
  }
  .lookMore:hover {
    transform: scale(1.05);
  }
  .lookMore:hover .moreIcon2 {
    display: block !important;
  }
  .lookMore:hover .moreIcon1 {
    display: none !important;
  }
  .lookMore {
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 40px;
    .moreText {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 18px;
      padding-right: 20px;
    }
    // .moreText:hover {
    //   color: rgba(36, 119, 255, 0.7);
    // }
    .moreIcon2 {
      display: none;
    }
    .moreIcon {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }
}
</style>