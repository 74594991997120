<template>
  <div>
    <div class="questionInfo" v-show="isInfo">
      <div class="back" @click="switchover()">
        <img src="@/assets/back2.png" alt="" />
        <span>返回</span>
      </div>
      <div class="title">{{ info.name }}</div>
      <div class="infoHtml" v-html="info.content"></div>
    </div>
    <div class="questionList flex_around" v-show="!isInfo">
      <div
        class="item"
        v-for="item in list"
        :key="item.questionId"
        @click="switchover(item.questionId)"
      >
        <div class="title">{{ item.name }}</div>
        <div class="hint">
          {{ item.remark }}
        </div>
        <div class="bottom flex_between">
          <div class="flex_start info">
            <div>查看详情</div>
            <img class="more2" src="@/assets/more2.png" alt="" />
          </div>
          <div class="time">{{ item.createTime }}</div>
        </div>
      </div>
    </div>
    <paging @tap="pagFn" :total="total" :pages="pages"></paging>
  </div>
</template>

<script>
import { replaceImg, formatRichText } from "@/tool/index";
import paging from "@/components/paging.vue";
import { questionList, questionGet } from "@/api/api";
export default {
  components: {
    paging,
  },
  data() {
    return {
      list: [],
      pages: 1,
      total: 1,
      isInfo: false,
      info: {},
    };
  },
  created() {
    this.questionList();
  },
  methods: {
    pagFn(e) {
      if (e == "prev") {
        this.prevFn();
      } else if (e == "next") {
        this.nextFn();
      } else if (e == "first") {
        this.pages = 1;
        this.questionList();
      } else if (e == "end") {
        this.pages = this.total;
        this.questionList();
      }
    },
    prevFn() {
      if (this.pages <= 1) {
        return;
      }
      this.pages--;
      this.questionList();
    },
    nextFn() {
      if (this.pages >= this.total) {
        return;
      }
      this.pages++;
      this.questionList();
    },
    async questionGet(id) {
      let xhr = await questionGet(id);
      if (xhr.code == 200) {
        xhr.data.content = replaceImg(xhr.data.content);
        xhr.data.content = formatRichText(xhr.data.content);
        this.info = xhr.data;
      } else {
        this.info = {};
      }
    },
    async switchover(id) {
      if (id) {
        await this.questionGet(id);
        this.isInfo = true;
      } else {
        this.isInfo = false;
      }
    },
    async questionList() {
      let xhr = await questionList(this.pages, 12);
      if (xhr.code == 200) {
        this.list = xhr.data.rows;
        this.total = Math.ceil(xhr.data.total / 12);
      } else {
        this.total = 0;
        this.list = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.questionInfo {
  width: 100%;
  position: relative;
  min-height: 813px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px;
  .infoHtml {
    font-size: 14px;
  }
  .title {
    text-align: center;
    padding-top: 113px;
    font-size: 20px;
    font-weight: 700;
    color: #3d3d3d;
    margin-bottom: 20px;
  }
  .back {
    font-size: 14px;
    font-weight: 400;
    color: #7f7f7f;
    position: absolute;
    top: 50px;
    right: 30px;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
.questionList {
  flex-wrap: wrap;
  margin-top: 50px;
  .item:hover {
    transform: scale(1.05);
  }
  .item {
    transition: all 500ms;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    width: 379px;
    min-height: 269px;
    border-radius: 15px;
    opacity: 1;
    border: 1px solid #7f7f7f;
    margin-bottom: 50px;
    padding: 30px;
    position: relative;
    padding-bottom: 60px;
    .bottom {
      padding: 0 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      .time {
        font-size: 14px;
        font-weight: 400;
        color: #3d3d3d;
        line-height: 1;
      }
      .info {
        font-size: 14px;
        font-weight: 400;
        color: #2477ff;
        line-height: 1;
        .more2 {
          width: 16px;
          height: 16px;
          margin-left: 20px;
        }
      }
    }
    .title {
      font-size: 18px;
      font-weight: 700;
      color: #3d3d3d;
      line-height: 1.2;
      position: relative;
    }
    .title::after {
      content: "";
      position: absolute;
      width: 72px;
      height: 1px;
      background: #7f7f7f;
      bottom: -15px;
      left: 0;
    }
    .hint {
      font-size: 14px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 1.5;
      margin-top: 30px;
    }
  }
}
</style>