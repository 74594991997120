<template>
  <div id="app">
    <navList v-if="!hideNav"></navList>
    <keep-alive> <router-view /> </keep-alive>
    <footCustom></footCustom>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideNav: false,
    };
  },
  created() {
    if (this.$route.meta && this.$route.meta.hideNav) {
      this.hideNav = true;
    }
  },

  watch: {
    $route() {
      console.log(this.$route);
      if (this.$route.meta && this.$route.meta.hideNav) {
        this.hideNav = true;
      } else {
        this.hideNav = false;
      }
    },
  },
};
</script>
<style lang="less">
/*网站整体灰白*/
// body  {
//   -webkit-filter: grayscale(100%); /* webkit */
//   -moz-filter: grayscale(100%); /*firefox*/
//   -ms-filter: grayscale(100%); /*ie9*/
//   -o-filter: grayscale(100%); /*opera*/
//   filter: grayscale(100%);
// }

@media only screen and (max-width: 750px) {
  div {
    cursor: none !important;
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.ellipsisTwo {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ellipsisThree {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.content {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  // background: #cccccc;
  // min-height: 100vh;
}
.flex_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flex_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex_around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
