<template>
  <div>
    <div class="flex_start box">
      <div class="ScrollBox">
        <div class="typeList">
          <div
            :class="[
              'classifyNameOne',
              typeIndex == item.questionId ? 'active' : '',
            ]"
            v-for="item in catalogue"
            :key="item.questionId"
            @click="checkType(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="leftBox">
        <div class="leftTitle">使用说明</div>
        <foldDown v-for="item in catalogue" :key="item.questionId">
          <div class="classifyNameOne" @click="checkTypePc(item)">
            {{ item.name }}
          </div>
          <div slot="fold" class="foldBox">
            <div
              v-for="child in item.children"
              :key="child.questionId"
              @click="checkTwo(child)"
            >
              {{ child.name }}
            </div>
          </div>
        </foldDown>
      </div>
      <div class="rightBox">
        <ul class="catalogue" v-show="!isInfo">
          <li
            v-for="item in threeCatalogue"
            :key="item.questionId"
            @click="questionGet(item.questionId)"
          >
            <div class="flex_between">
              <div class="name">{{ item.name }}</div>
              <div class="time">更新时间：{{ item.createTime }}</div>
            </div>
          </li>
        </ul>
        <div v-show="isInfo">
          <div class="back" @click="backList()">
            <img src="@/assets/back2.png" alt="" />
            <span>返回</span>
          </div>
          <div class="title">{{ info.name }}</div>
          <div class="infoHtml" v-html="info.content"></div>
        </div>
      </div>
    </div>
    <el-drawer modal title="" :visible.sync="drawer" direction="btt">
      <div class="foldChildBox">
        <div
          @click="checkTwo(item)"
          v-for="item in twoCatalogue"
          :key="item.questionId"
        >
          {{ item.name }}
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import foldDown from "@/components/foldDown.vue";
import { instructionsCatalogue, questionGet } from "@/api/api";
import { replaceImg, formatRichText } from "@/tool/index";
export default {
  components: {
    foldDown,
  },
  data() {
    return {
      drawer: false,
      typeIndex: 1,
      catalogue: [],
      twoCatalogue: [],
      threeCatalogue: [],
      isInfo: false,
      info: {},
    };
  },
  created() {
    this.instructionsCatalogue();
  },
  methods: {
    backList() {
      if (this.threeCatalogue.length != 0) {
        this.isInfo = false;
      }
    },
    async questionGet(id) {
      let xhr = await questionGet(id);
      if (xhr.code == 200) {
        xhr.data.content = replaceImg(xhr.data.content);
        xhr.data.content = formatRichText(xhr.data.content);
        this.info = xhr.data;
        this.isInfo = true;
      } else {
        this.info = {};
      }
    },
    checkTwo(item) {
      this.drawer = false;
      if (item.children && item.children.length != 0) {
        this.threeCatalogue = item.children;
        this.backList();
      } else {
        this.questionGet(item.questionId);
      }
    },
    checkType(item) {
      this.typeIndex = item.questionId;
      if (item.children && item.children.length != 0) {
        this.twoCatalogue = item.children;
        this.drawer = true;
        this.backList();
      } else {
        this.questionGet(item.questionId);
      }
    },
    checkTypePc(item) {
      this.typeIndex = item.questionId;
      if (!item.children || item.children.length == 0) {
        this.questionGet(item.questionId);
      } else {
        this.twoCatalogue = item.children;
        this.backList();
      }
    },
    async instructionsCatalogue() {
      let xhr = await instructionsCatalogue();
      if (xhr.code == 200) {
        this.catalogue = xhr.data.rows;
        if (this.catalogue[0]) {
          this.checkTypePc(this.catalogue[0]);
          if (
            this.catalogue[0].children &&
            this.catalogue[0].children.length != 0
          ) {
            this.checkTwo(this.catalogue[0].children[0]);
          }
        }
      } else {
        this.catalogue = [];
      }
    },
  },
};
</script>

<style scoped lang="less">
@media only screen and (max-width: 750px) {
  .leftBox {
    display: none !important;
  }
  .rightBox {
    padding: 35px 20px !important;
  }
  .box {
    display: block !important;
    .name {
      font-size: 18px !important;
    }
    .time {
      color: #aaaaaa !important;
    }
  }
  .typeList {
    display: block !important;
  }
  .header {
    height: 100px !important;
    .name {
      font-size: 20px !important;
      line-height: 100px !important;
    }
    .navItem {
      font-size: 14px !important;
      margin-left: 30px !important;
      line-height: 100px !important;
      cursor: none !important;
    }
  }
  .headerCopy {
    height: 100px !important;
    margin-bottom: 25px !important;
  }
}
.foldChildBox {
  text-align: center;
  padding-bottom: 30px;
  div {
    font-size: 18px;
    font-weight: 400;
    color: #7f7f7f;
    line-height: 1.5;
    text-align: center;
    padding: 7px 0;
    border-bottom: 1px solid #eeeeee;
  }
  div:last-child {
    border-bottom: none;
  }
}
.leftTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #3d3d3d;
  margin-bottom: 30px;
}
.ScrollBox {
  overflow-x: auto;
  margin-bottom: 20px;
  //   -ms-overflow-style: none;
  //   overflow: -moz-scrollbars-none;
}
/*滚动条样式*/
.ScrollBox::-webkit-scrollbar {
  width: 4px !important;
  height: 4px;
}
/*IE 10+*/

.ScrollBox::-webkit-scrollbar-thumb {
  width: 1px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.ScrollBox::-webkit-scrollbar-track {
  width: 2px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}
.typeList {
  white-space: nowrap;
  padding-bottom: 10px;
  display: none;
  .active {
    background-color: #2477ff !important;
    color: #ffffff !important;
  }
  .classifyNameOne {
    transition: all 500ms;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    background-color: rgb(242, 242, 242);
    border-radius: 5px;
    margin-right: 10px;
    color: #3d3d3d;
  }
}
.box {
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 100px;
  .leftBox {
    width: 220px;
    min-height: 300px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    margin-right: 30px;
    padding: 30px;
    .classifyNameOne {
      flex: 1;
      font-size: 18px;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 1.5;
      text-align: center;
      padding: 15px 0;
    }
    .foldBox {
      cursor: pointer;
      div {
        font-size: 14px;
        font-weight: 400;
        color: #7f7f7f;
        line-height: 1.5;
        text-align: center;
        padding: 7px 0;
      }
    }
  }
  .rightBox {
    min-height: 300px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    flex: 1;
    padding: 35px 50px;
    position: relative;
    .infoHtml {
      font-size: 14px;
    }
    .title {
      text-align: center;
      padding-top: 113px;
      font-size: 20px;
      font-weight: 700;
      color: #3d3d3d;
      margin-bottom: 20px;
    }
    .back {
      font-size: 14px;
      font-weight: 400;
      color: #7f7f7f;
      position: absolute;
      top: 50px;
      right: 30px;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .catalogue li:last-child {
      border-bottom: none !important;
    }
    .catalogue {
      //   list-style-type: circle;
      color: #d8d8d8;
      li {
        border-bottom: 1px dashed #d8d8d8;
        padding: 15px 0;
        cursor: pointer;
        div {
          flex-wrap: wrap;
          font-size: 14px;
          font-weight: 400;
          color: #3d3d3d;
          line-height: 1.5;
        }
        // .time {
        //   //   text-align: right;
        //   //   width: 100%;
        // }
      }
    }
  }
}
</style>