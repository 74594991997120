<template>
  <div class="serveInfo">
    <img
      style="margin-top: 50px"
      v-if="isPhone"
      src="@/assets/bg2.png"
      alt=""
      srcset=""
    />
    <img
      style="margin-top: 100px"
      v-else
      src="@/assets/bg2.png"
      alt=""
      srcset=""
    />
  </div>
</template>

<script>
import { IsClientScope } from "@/tool/index";
let watchWindowSize;
export default {
  data() {
    return {
      isPhone: false,
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 960);
    };
    window.addEventListener("resize", watchWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
  },
};
</script>

<style scoped lang="less">
.serveInfo {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>