<template>
  <div>
    <!-- 手机端 -->
    <nav v-if="isPhone" class="phoneNav">
      <img src="@/assets/menu.png" @click="openMenu" alt="menu" class="menu" />
      <el-drawer
        :withHeader="false"
        :show-close="false"
        :visible.sync="isOpenPhoneMenu"
        direction="rtl"
      >
        <img src="@/assets/logo.png" class="logo" alt="logo" />
        <div v-for="(item, index) in navList" :key="item.id" class="itemBox">
          <div class="itemBox">
            <div
              class="item"
              @click="check(item, index)"
              :style="{ color: activeId == item.id ? '#2477ff' : '#3D3D3D' }"
            >
              {{ item.name }}
            </div>
            <div
              v-if="item.child && item.child.length != 0"
              class="pullDownBox"
              :style="{
                height: item.isShow ? item.child.length * 35 + 'px' : '0px',
              }"
            >
              <div
                class="itemChild"
                v-for="child in item.child"
                :key="child.id"
                @click="checkChild(child, item)"
              >
                {{ child.name }}
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </nav>
    <!-- pc 端 -->
    <div v-else :class="[rollTop >= 100 ? 'sticky' : '', 'pcNavBox']">
      <nav class="pcNav flex_between">
        <img src="@/assets/logo.png" class="logo" alt="logo" />
        <div class="flex_between navList">
          <div v-for="(item, index) in navList" :key="item.id" class="itemBox">
            <div
              :class="['item', activeId == item.id ? 'active' : '']"
              @click="check(item, index)"
            >
              {{ item.name }}
            </div>
            <div
              v-if="item.child && item.child.length != 0"
              class="pullDownBox"
            >
              <div class="popper__arrow"></div>
              <div class="pullDown">
                <div
                  class="itemChild"
                  v-for="child in item.child"
                  :key="child.id"
                  @click="checkChild(child, item)"
                >
                  {{ child.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { IsClientScope } from "@/tool/index";
import config from "@/api/config";
let watchWindowSize;
let watchScroll;
export default {
  data() {
    return {
      rollTop: 0,
      isPhone: false,
      activeId: 1,
      isOpenPhoneMenu: false, //是否展示手机端菜单
      shadeTime: undefined,
      navList: [
        {
          name: "网站首页",
          id: 1,
          isShow: false,
          page: "/",
        },
        {
          name: "关于酷骁",
          id: 2,
          isShow: false,
          page: "/aboutUs",
          child: [
            {
              page: "/aboutUs",
              name: "企业介绍",
              id: 2001,
              param: {
                type: 1,
              },
            },
            {
              page: "/aboutUs",
              name: "企业风采",
              id: 2002,
              param: {
                type: 2,
              },
            },
            {
              page: "/aboutUs",
              name: "企业文化",
              id: 2003,
              param: {
                type: 3,
              },
            },
            {
              page: "/aboutUs",
              name: "管理团队",
              id: 2004,
              param: {
                type: 4,
              },
            },
            {
              page: "/aboutUs",
              name: "合作伙伴",
              id: 2005,
              param: {
                type: 5,
              },
            },
          ],
        },
        {
          name: "产品服务",
          id: 4,
          isShow: false,
          page: "/product",
        },
        {
          name: "新闻中心",
          id: 5,
          isShow: false,
          page: "/newList",
          child: [
            {
              name: "全部新闻",
              id: 5001,
              page: "/newList",
              param: {
                type: undefined,
              },
            },
            {
              name: "公司动态",
              id: 5002,
              page: "/newList",
              param: {
                type: 1,
              },
            },
            {
              name: "行业资讯",
              id: 5003,
              page: "/newList",
              param: {
                type: 2,
              },
            },
          ],
        },
        {
          name: "联系我们",
          id: 6,
          isShow: false,
          page: "/connectUs",
        },
        {
          name: "酷骁APP",
          id: 7,
          isShow: false,
          page: "/appExhibition",
        },
        {
          name: "登录入口",
          id: 8,
          isShow: false,
          page: config.jump,
          type: "link",
        },
      ],
    };
  },
  created() {
    let that = this;
    that.isPhone = IsClientScope(0, 960);
    watchWindowSize = function () {
      that.isPhone = IsClientScope(0, 960);
    };
    watchScroll = function (e) {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      that.rollTop = scrollTop;
    };
    window.addEventListener("resize", watchWindowSize);
    window.addEventListener("scroll", watchScroll);
    for (let index = 0; index < this.navList.length; index++) {
      const element = this.navList[index];
      if (element.page == this.$route.path) {
        this.activeId = element.id;
        break;
      }
    }
  },
  watch: {
    $route() {
      for (let index = 0; index < this.navList.length; index++) {
        const element = this.navList[index];
        if (element.page == this.$route.path) {
          this.activeId = element.id;
          break;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", watchWindowSize);
    window.removeEventListener("scroll", watchScroll);
  },
  methods: {
    ...mapActions(["setPage"]),
    openMenu() {
      this.isOpenPhoneMenu = !this.isOpenPhoneMenu;
    },
    checkChild(item, parent) {
      if (parent) {
        this.activeId = parent.id;
      }
      this.setPage(item);
      if (item.page != this.$route.path) {
        this.$router.push(item.page);
        window.scrollTo(0, 0);
      }
      this.isOpenPhoneMenu = false;
    },
    check(item, index) {
      if (item.type == "link") {
        window.location.href = item.page;
        return;
      }
      this.activeId = item.id;
      if (!this.isPhone) {
        this.setPage(item);
        if (item.page != this.$route.path) {
          this.$router.push(item.page);
          window.scrollTo(0, 0);
        }
        return;
      }
      if (index != undefined) {
        this.navList[index].isShow = !this.navList[index].isShow;
      }
      if (!item.child || item.child.length == 0) {
        this.isOpenPhoneMenu = false;
        this.setPage(item);
        if (item.page != this.$route.path) {
          this.$router.push(item.page);
          window.scrollTo(0, 0);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media only screen and (min-width: 750px) and (max-width: 960px) {
  .menu {
    width: 60px !important;
  }
}
.phoneNav {
  .menu {
    width: 40px;
    height: auto;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 10;
  }
  .logo {
    width: 80%;
    display: block;
    margin: 0 auto;
    height: auto;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .pullDownBox {
    overflow: hidden;
    transition: height 500ms;
    .itemChild {
      line-height: 35px;
      font-size: 16px;
      color: #aaaaaa;
      text-align: center;
    }
  }

  .item {
    color: #3d3d3d;
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    border-bottom: 1px #eeeeee dashed;
  }
}
.sticky {
  position: fixed !important;
  top: 0 !important;
}
.pcNavBox {
  max-width: 1240px;
  min-width: 980px;
  width: 100%;
  padding: 0 20px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .pcNav {
    width: 100%;
    height: 140px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0 46px;
    .logo {
      width: 225px;
      height: 80px;
      margin-left: 18px;
      margin-right: 48px;
    }

    .itemBox {
      position: relative;
      .pullDownBox {
        transition: all 800ms;
        height: 0px;
        // opacity: 0;
        overflow: hidden;
        top: 50px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        .popper__arrow {
          width: 16px;
          height: 16px;
          // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          transform: rotate(45deg) translateX(-50%) translateY(-2px);
          left: 50%;
          top: 10px;
          position: absolute;
          z-index: 3;
          filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.03));
          border-top: #eeeeee 1px solid;
          border-left: #eeeeee 1px solid;
        }
        // .popper__arrow:after {
        //   bottom: 1px;
        //   border-top-color: #fff;
        //   border-bottom-width: 0;
        //   content: " ";
        //   border-width: 5px;
        //   position: absolute;
        //   display: block;
        //   width: 0;
        //   height: 0;
        //   border-color: transparent;
        //   border-style: solid;
        // }
        .pullDown {
          z-index: 2;
          background-color: #ffffff;
          width: 100px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          min-height: 30px;
          border: #eeeeee 1px solid;
          position: relative;
          top: 10px;
          padding: 12px;
          .itemChild {
            text-align: center;
            font-size: 14px;
            line-height: 30px;
            border-bottom: #eeeeee 1px solid;
            cursor: pointer;
          }
          .itemChild:last-child {
            border-bottom: none;
          }
        }
      }
    }
    .itemBox:hover .pullDownBox {
      height: 300px !important;
      opacity: 1 !important;
    }
    .navList {
      flex: 1;
      .item {
        font-size: 18px;
        font-weight: 400;
        color: #3d3d3d;
        position: relative;
        cursor: pointer;
        transition: transform 500ms;
      }

      .item::after {
        content: "";
        display: block;
        position: absolute;
        width: 0px;
        height: 5px;
        background: #2477ff;
        border-radius: 2px;
        bottom: -18px;
        transition: width 200ms;
        left: 50%;
        transform: translateX(-50%);
      }
      .item:hover {
        // width: 72px !important;
        transform: scale(1.1);
      }
      .active::after {
        width: 72px;
      }
    }
  }
}
</style>